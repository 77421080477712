import { useQuery } from 'react-query'

import { useApi } from 'api/Api'

export const useGetCustomerPaymentHistory = (email) => {
  const { apiCloud } = useApi()

  return useQuery(['/stripe_api/get_customer_payment_history'], async () => {
    const results = await apiCloud.post('/stripe_api/get_customer_payment_history', {
      email: email
    })

    return results.data.paymentsHistory
  })
}
