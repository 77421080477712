import _ from 'lodash'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import { countDailyFocusTime } from '../metrics/metrics'

export const countFocusTime = (events) => countDailyFocusTime(events)

export const countMeetingsAmount = (events) => events.length > 7

export const countMeetingsRow = (events) => {
  return !!events.find((event) => event.noBreak)
}

export const countMeetingsOverload = (events) => {
  if (events.length) {
    const firstMeeting = events[0]
    const lastMeeting = events[events.length - 1]
    return differenceInMinutes(lastMeeting.end, firstMeeting.start) > 480
  }
  return false
}

export const countOverlays = (events) => {
  return !!events.find((event) => event.overlay)
}

export const highlightOverlaysAndNoBreaks = (events) => {
  const stack = []
  const arr = events.flatMap(({ start, end }, i) => [
    { time: start, bracket: '(', id: i },
    { time: end, bracket: ')', id: i }
  ])
  const sortedArr = _.sortBy(arr, 'time')
  sortedArr.forEach((el, index, arr) => {
    const { bracket } = el
    if (bracket === '(') {
      if (arr[index - 1]?.bracket === ')' && !differenceInMinutes(arr[index - 1].time, el.time)) {
        events[el.id].noBreak = true
        events[arr[index - 1].id].noBreak = true
      }
      stack.push(el)
      if (stack.length > 1) {
        stack
          .map(({ id }) => id)
          .forEach((id) => {
            events[id].overlay = true
            events[id].noBreak = true
          })
      }
    }
    if (bracket === ')') {
      stack.pop()
    }
  })

  return events
}
