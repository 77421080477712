import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider, CssBaseline } from '@mui/material'
import { QueryClient, QueryClientProvider } from 'react-query'
import { GoogleOAuthProvider } from '@react-oauth/google'
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4'

import { ApiProvider } from 'api/Api'
import { AuthProvider } from 'auth/AuthContext'
import { DateProvider } from 'Date/DateContext'
import { Routing } from 'Routing/Routing'
import { theme } from 'ThemeProvider/ThemeProvider'

import './styles.scss'
import { CLIENT_ID } from './config/config'

const App = () => {
  const queryClient = new QueryClient()

  useEffect(() => {
    ReactGA.initialize('G-QJKE197KNE')
    TagManager.initialize({ gtmId: 'G-WYDN491KPL' })
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <GoogleOAuthProvider clientId={CLIENT_ID}>
          <QueryClientProvider client={queryClient}>
            <ApiProvider>
              <AuthProvider>
                <DateProvider>
                  <Routing />
                </DateProvider>
              </AuthProvider>
            </ApiProvider>
          </QueryClientProvider>
        </GoogleOAuthProvider>
      </Router>
    </ThemeProvider>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))
