import React from 'react'
import { AddCircle } from '@mui/icons-material'
import { IconButton, Stack, Tooltip } from '@mui/material'

import { AccountButton, AccountButtonSkeleton } from 'components'
import logo from 'assets/images/duck.svg'
import { useAccountLogin } from 'hooks'

export const Accounts = ({ accounts, isLoading }) => {
  const { login, isLoading: isLoadingNewAccount } = useAccountLogin()
  const { calendar_accounts = [], expired_accounts = [] } = accounts

  return (
    <Stack direction="row" alignItems="center" mt={4} mb={4} spacing={1} sx={{ overflowX: 'auto' }}>
      {isLoading ? (
        <>
          <AccountButtonSkeleton />
          <AccountButtonSkeleton />
        </>
      ) : (
        <>
          {calendar_accounts.map(({ email, name, picture, main }) => (
            <AccountButton key={email} email={email} name={name} picture={picture} main={main} />
          ))}
          {expired_accounts.map((email) => (
            <AccountButton
              key={email}
              email={email}
              name={'Click to reauthenticate'}
              picture={logo}
              main={false}
              isExpired={true}
            />
          ))}
          {isLoadingNewAccount && <AccountButtonSkeleton />}
          <Tooltip title="Add new calendar" arrow>
            <IconButton onClick={() => login()}>
              <AddCircle />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Stack>
  )
}
