import React from 'react'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { useForm } from 'react-hook-form'

import { useAuth } from 'auth/AuthContext'
import { useSaveFeedback } from './hooks'
import discord_icon from 'assets/images/discord.png'

export const Feedback = () => {
  const { user } = useAuth()
  const { mutate: saveFeedback, isLoading, isSuccess } = useSaveFeedback()
  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: user.email
    }
  })

  const onSubmit = (data) => {
    saveFeedback({ email: data.email, feedback: data.feedback })
  }

  return (
    <Box sx={{ maxWidth: 1000, margin: '0 auto' }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={7} display="flex" justifyContent="center">
          <Box maxWidth={500}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={2} alignItems="start">
                <Typography variant="h5" fontWeight={700}>
                  Share your thoughts
                </Typography>
                <Typography fontSize={16} fontWeight={600}>
                  We appreciate your honest opinion. Help us become even better, share your
                  thoughts, ideas, feature proposals. Feel free to contact us at any time.
                </Typography>
                {isSuccess ? (
                  <Alert severity="success">Thanks for your feedback, we do appreciate it!</Alert>
                ) : (
                  <>
                    <TextField
                      multiline
                      rows={7}
                      fullWidth
                      placeholder="Share your thoughts with us..."
                      required
                      {...register('feedback', { required: true })}
                    />
                    <TextField
                      sx={{ maxWidth: 300 }}
                      fullWidth
                      size="small"
                      variant="outlined"
                      label="Email"
                      required
                      {...register('email', { required: true })}
                    />
                    <Button variant="contained" type="submit">
                      {isLoading ? <CircularProgress size={25} /> : 'Submit'}
                    </Button>
                  </>
                )}
              </Stack>
            </form>
          </Box>
        </Grid>
        <Grid item xs={12} md={5} display="flex" justifyContent="center" alignItems="center">
          <Paper
            square={false}
            variant="outlined"
            sx={{ p: 3, backgroundColor: '#f6f6f6', maxWidth: 500 }}>
            <Stack spacing={2} alignItems="start">
              <Typography variant="h5" fontWeight={700}>
                Discord Community
              </Typography>
              <Box m="16px 0">
                <img src={discord_icon} height={50} />
              </Box>
              <Typography fontSize={16} fontWeight={600}>
                Have any questions about dailyduck?
              </Typography>
              <Typography fontSize={16} fontWeight={600}>
                Join our Discord channel to discover tips, share feedback, and stay up to speed on
                the latest product announcements.
              </Typography>
              <Button variant="outlined" target="_blank" href="https://discord.gg/4sQq2EdU">
                Go to Discord
              </Button>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}
