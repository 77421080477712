import React from 'react'
import { Box, Grid } from '@mui/material'
import addMonths from 'date-fns/addMonths'
import endOfWeek from 'date-fns/endOfWeek'
import startOfWeek from 'date-fns/startOfWeek'
import addWeeks from 'date-fns/addWeeks'

import { MetricCard, MetricChart, CanonicalHelmet } from 'components'
import { useSortEventsByDays } from '../../hooks/useSortEventsByDays'
import { useCountMetrics } from '../../hooks/useCountMetrics'
import { METRICS_TYPES } from 'metrics'
import { useGetEvents } from '../../hooks/useGetEvents'

const timeMin = addMonths(new Date(), -12).toISOString()
const timeMax = endOfWeek(new Date()).toISOString()
const lastMonthStartDate = startOfWeek(addWeeks(new Date(), -3))
const currentWeekEndDate = endOfWeek(new Date())

export const MetricsPage = () => {
  const { events, isLoading } = useGetEvents({ timeMin, timeMax })
  const sortedEvents = useSortEventsByDays(events, lastMonthStartDate, currentWeekEndDate)
  const formattedEvents = sortedEvents.flatMap(({ events }) => events)
  const lastMonthMetrics = useCountMetrics([{ day: '', events: formattedEvents }], 20)

  return (
    <Box sx={{ maxWidth: 1000, margin: '0 auto' }}>
      <CanonicalHelmet />
      <Grid container spacing={2}>
        {METRICS_TYPES.map(({ value }) => (
          <Grid key={value} item xs={12} sm={6} md={3}>
            <MetricCard
              key={value}
              metric={value}
              value={lastMonthMetrics[0][value]}
              isLoading={isLoading}
            />
          </Grid>
        ))}
        <Grid item xs={12} sm={12} md={12}>
          <MetricChart events={events} isLoading={isLoading} />
        </Grid>
      </Grid>
    </Box>
  )
}
