import React from 'react'
import { styled, Typography } from '@mui/material'

export const TypographyText = styled((props) => <Typography {...props} component="span" />)(
  ({ weight, size, color }) => ({
    fontWeight: weight,
    fontSize: size,
    lineHeight: 1,
    color: color
  })
)
