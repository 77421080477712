import React, { useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  CircularProgress
} from '@mui/material'
import { Close, CheckCircle } from '@mui/icons-material'

import { useAuth } from 'auth/AuthContext'
import { useCreateCheckoutSession, useCreatePortalSession } from './hooks'
import { convertFromTimestampToDate } from './utils'
import { SUBSCRIPTION_PLANS } from '../../constants'
import { SelectPlanCard } from './SelectPlanCard'

export const PlanSelection = ({ open, setOpen, currentSubscription }) => {
  const { user } = useAuth()
  const fullScreen = useMediaQuery('(max-width:900px)')
  const [selectedPlan, setSelectedPlan] = useState(null)
  const {
    mutate: createCheckoutSession,
    isLoading: isLoadingCheckoutSession,
    isSuccess: checkoutSessionSuccess
  } = useCreateCheckoutSession(user.email)
  const {
    mutate: createPortalSession,
    isLoading: isLoadingPortalSession,
    isSuccess: portalSessionSuccess
  } = useCreatePortalSession(user.email, 'subscription_cancel')

  const isLoading = isLoadingCheckoutSession || isLoadingPortalSession
  const isSuccess = checkoutSessionSuccess || portalSessionSuccess

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={() => setOpen(false)}>
        <DialogContent>
          <Box position="absolute" top={16} right={16}>
            <IconButton
              onClick={() => {
                setSelectedPlan(null)
                setOpen(false)
              }}>
              <Close />
            </IconButton>
          </Box>
          <Typography variant="h5" textAlign="center" fontWeight={700}>
            Select a plan
          </Typography>
          <Stack
            direction={fullScreen ? 'column' : 'row'}
            maxWidth={fullScreen ? 300 : '100%'}
            spacing={2}
            justifyContent="space-between"
            sx={{ margin: '20px auto 0' }}>
            <SelectPlanCard
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              currentPlan={user.plan}
              cardPlan={SUBSCRIPTION_PLANS.FREE}
            />
            <SelectPlanCard
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              currentPlan={user.plan}
              cardPlan={SUBSCRIPTION_PLANS.BASIC}
            />
            <Paper variant="outlined" square={false} sx={{ flex: 1, p: 2 }}>
              <Box mb={2} textAlign="center">
                <Typography variant="h6" fontWeight={600} color="#181D25" sx={{ opacity: 0.6 }}>
                  PREMIUM
                </Typography>
                <Typography variant="h5" fontWeight={700}>
                  Coming Soon
                </Typography>
                <Button variant="outlined" sx={{ width: 150, mt: 2 }}>
                  Contact us
                </Button>
              </Box>
            </Paper>
          </Stack>
          <Box textAlign="center" mt={4}>
            <Stack alignItems="center" spacing={1}>
              {currentSubscription?.cancel_at_period_end && selectedPlan && (
                <Typography fontSize={12}>
                  The subscription is already set to be canceled at period end.
                  <br />
                  You will be transferred to a FREE Plan on{' '}
                  <strong>{convertFromTimestampToDate(currentSubscription?.cancel_at)}</strong>
                </Typography>
              )}
              <Button
                size="small"
                variant={isLoading || isSuccess ? 'outlined' : 'contained'}
                disabled={!selectedPlan || currentSubscription?.cancel_at_period_end}
                color={isSuccess ? 'success' : 'primary'}
                onClick={() => {
                  if (selectedPlan === SUBSCRIPTION_PLANS.FREE) {
                    return createPortalSession()
                  } else {
                    return createCheckoutSession()
                  }
                }}>
                {isLoading ? (
                  <CircularProgress size={25} />
                ) : isSuccess ? (
                  <CheckCircle color="success" />
                ) : (
                  'Continue on Stripe'
                )}
              </Button>
              <Button
                size="small"
                disabled={!selectedPlan}
                variant="text"
                color="error"
                onClick={() => setSelectedPlan(null)}>
                Deselect
              </Button>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}
