import React from 'react'
import { Box, Button, Link, Stack, Typography } from '@mui/material'

export const CallToAction = () => {
  return (
    <Box sx={{ padding: '96px 16px', backgroundColor: '#f9fafb' }}>
      <Box
        padding={{ xs: '40px', md: '96px' }}
        sx={{
          backgroundColor: '#181d25',
          maxWidth: 1200,
          margin: '0 auto',
          borderRadius: 5,
          boxShadow: 'rgb(38, 57, 77) 0px 20px 30px -10px'
        }}>
        <Stack
          alignItems="center"
          maxWidth={500}
          textAlign="center"
          spacing={4}
          sx={{ margin: '0 auto' }}>
          <Typography variant="h4" component="h2" color="#fff" fontWeight={700}>
            Ready to sync your Calendars?
          </Typography>
          <Typography color="#d1d5d8">
            Take control of your time, sync effortlessly, and find balance with Dailyduck! Start
            managing your calendars together for a harmonious work-life blend.
          </Typography>
          <Button size="large" variant="contained" component={Link} href="/login">
            Start for free
          </Button>
        </Stack>
      </Box>
    </Box>
  )
}
