import React from 'react'

import { TypographyText } from './Typography'

export const ChartLegend = ({ name, color }) => {
  return (
    <TypographyText
      size={12}
      weight={600}
      sx={{
        color: '#9FA2B4',
        position: 'relative',
        margin: '5px 0',
        '&::before': {
          content: '""',
          width: '24px',
          height: '2px',
          position: 'absolute',
          bottom: '6px',
          left: '-40px',
          backgroundColor: color
        }
      }}>
      {name}
    </TypographyText>
  )
}
