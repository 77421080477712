import { useMutation, useQueryClient } from 'react-query'

import { useApi } from 'api/Api'

export const useAddAccount = () => {
  const { apiCloud } = useApi()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ source_email, calendar_email, refresh_token, main = false }) => {
      const results = await apiCloud.post('/add_calendar', {
        source_email: source_email,
        calendar_email: calendar_email,
        refresh_token: refresh_token,
        main: main
      })

      return results.data
    },
    onSuccess: ({ calendar_accounts }) => {
      queryClient.setQueryData(['/show_account_calendars'], (prev) => {
        return {
          ...prev,
          calendar_accounts,
          expired_accounts: prev.expired_accounts.filter(
            (email) => !calendar_accounts.some((obj) => obj.email === email)
          )
        }
      })
    }
  })
}
