import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Sync, ErrorOutline } from '@mui/icons-material'
import { Box, Paper, Stack, Divider, Button } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

import { TypographyText, SelectAccount } from 'components'
import { useAuth } from 'auth/AuthContext'
import { useGetAccounts } from '../CalendarSync/hooks'
import { RadioDirectionSync } from './RadioDirectionSync'
import { SyncPreview } from './SyncPreview'
import { useSubscribeToBackgroundSyncer } from './hooks'

export const useStyles = makeStyles(() =>
  createStyles({
    rotateIcon: {
      animation: '$spin 1s linear infinite'
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(360deg)'
      },
      '100%': {
        transform: 'rotate(0deg)'
      }
    }
  })
)

export const CreateBackgroundSync = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { user } = useAuth()
  const { data: accounts = { calendar_accounts: [], expired_accounts: [] }, isLoading } =
    useGetAccounts(user.email)
  const { mutate: syncCalendars, isLoading: isSyncing } = useSubscribeToBackgroundSyncer()
  const [syncType, setSyncType] = useState('1')
  const [sourceAccount, setSourceAccount] = useState(null)
  const [targetAccount, setTargetAccount] = useState(null)

  const hasMainAccount = useMemo(() => {
    if (sourceAccount && targetAccount) {
      return [sourceAccount, targetAccount].includes(user.email)
    }

    return true
  }, [sourceAccount, targetAccount])

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      spacing={4}
      alignItems={{ xs: 'center', md: 'start' }}
      justifyContent="center">
      <Paper variant="outlined" square={false} sx={{ width: '100%', maxWidth: 500 }}>
        <Box sx={{ padding: 2 }}>
          <TypographyText>Synchronize calendars</TypographyText>
        </Box>
        <Divider />
        <Stack alignItems="start" spacing={4} p={4}>
          <Box sx={{ width: '100%' }}>
            <TypographyText size={14}>
              {syncType === '1' ? 'Source Calendar' : 'Calendar 1'}
            </TypographyText>
            <SelectAccount
              items={accounts.calendar_accounts}
              account={sourceAccount}
              setAccount={setSourceAccount}
              isLoading={isLoading}
            />
          </Box>
          <Box sx={{ width: '100%' }}>
            <TypographyText size={14}>
              {syncType === '1' ? 'Target Calendar' : 'Calendar 2'}
            </TypographyText>
            <SelectAccount
              items={accounts.calendar_accounts}
              account={targetAccount}
              setAccount={setTargetAccount}
              isLoading={isLoading}
            />
          </Box>
          {!hasMainAccount && (
            <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
              <ErrorOutline fontSize="small" color="error" sx={{ marginRight: 1 }} />
              <TypographyText size={14} color="error">
                Your main account must me one of the pair calendars
              </TypographyText>
            </Box>
          )}
          {sourceAccount === targetAccount && (
            <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
              <ErrorOutline fontSize="small" color="error" sx={{ marginRight: 1 }} />
              <TypographyText size={14} color="error">
                Please provide two different calendars
              </TypographyText>
            </Box>
          )}
          <RadioDirectionSync syncType={syncType} setSyncType={setSyncType} />
          <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
            <Button variant="outlined" onClick={() => navigate('/calendar-sync')}>
              Cancel
            </Button>
            <Button
              variant="contained"
              endIcon={<Sync className={isSyncing && classes.rotateIcon} />}
              disabled={
                !sourceAccount ||
                !targetAccount ||
                !hasMainAccount ||
                sourceAccount === targetAccount
              }
              onClick={(event) => {
                event.preventDefault()

                if (!isSyncing) {
                  return syncCalendars({
                    account_email: user.email,
                    source_email: sourceAccount,
                    target_email: targetAccount,
                    active: user.plan === 'BASIC',
                    direction: Number(syncType)
                  })
                }
              }}>
              Start syncing
            </Button>
          </Stack>
        </Stack>
      </Paper>
      <SyncPreview syncType={syncType} />
    </Stack>
  )
}
