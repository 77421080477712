import styled from 'styled-components'

export const Content = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 40px 20px;

  span {
    font-size: 18px;
  }
  ol {
    padding-left: 20px;
  }
  ul {
    list-style-type: none;

    & > li {
      margin-bottom: 12px;
    }
  }
  li > span {
    font-size: 16px;
  }
  h1 {
    margin-top: 32px;
  }
  h2 {
    font-size: 20px;
    margin: 10px 0;
  }
  h3 {
    font-size: 22px;
    margin: 60px 0 10px 0;
  }
  img {
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
  }
`
