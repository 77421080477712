import { useMemo } from 'react'

import { useSortEventsByDays } from '../../hooks/useSortEventsByDays'
import { useCountNudges } from '../../hooks/useCountNudges'
import { sortNudgesByPriority } from './utils'

export const useNudges = (events, activePeriod) => {
  const sortedEvents = useSortEventsByDays(events, activePeriod?.start, activePeriod?.end)
  const formattedEvents = sortedEvents.map(({ day, events }) => ({
    day,
    events: events[0] || events
  }))

  const nudgesDaily = useCountNudges(formattedEvents)

  const nudgesValues = useMemo(
    () =>
      nudgesDaily.reduce((acc, cur) => {
        const { day, ...data } = cur
        const nudges = [...Object.keys(data).filter((nudge) => data[nudge])]
        const res = [...acc, ...nudges]
        const nudgesValues = [...new Set(res)]
        return nudgesValues.sort(sortNudgesByPriority).slice(0, 5)
      }, []),
    [nudgesDaily]
  )
  return { nudgesValues, nudgesDaily }
}
