import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import { Link as MuiLink, Typography, Breadcrumbs as MuiBreadcrumbs } from '@mui/material'
import { NavigateNext } from '@mui/icons-material'

const breadcrumbNameMap = {
  '/blog': 'Blog',
  '/blog/time-mastery': 'Time Mastery',
  '/blog/max-productivity': 'Max Productivity'
}

export const Breadcrumbs = () => {
  const location = useLocation()
  const pathnames = location.pathname.split('/').filter((x) => x)

  return (
    <MuiBreadcrumbs separator={<NavigateNext fontSize="small" />}>
      {pathnames.map((name, index) => {
        const last = index === pathnames.length - 1
        const to = `/${pathnames.slice(0, index + 1).join('/')}`

        return last ? (
          <Typography color="primary" key={to}>
            {breadcrumbNameMap[to]}
          </Typography>
        ) : (
          <MuiLink key={name} color="text.secondary" component={Link} underline="hover" to={to}>
            {breadcrumbNameMap[to]}
          </MuiLink>
        )
      })}
    </MuiBreadcrumbs>
  )
}
