import React from 'react'
import {
  Box,
  createTheme,
  Dialog,
  IconButton,
  Link as MuiLink,
  List,
  ListItem,
  Stack,
  ThemeProvider
} from '@mui/material'
import { Close, Menu } from '@mui/icons-material'
import { headerNavLinks } from './consts'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#FFFFFF'
    },
    secondary: {
      main: '#3751FF'
    }
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none'
        }
      }
    }
  }
})

export const MobileNavMenu = ({ open, setOpen }) => {
  return (
    <>
      <Box mr={2}>
        <IconButton onClick={() => setOpen(true)} sx={{ color: '#191919' }}>
          <Menu />
        </IconButton>
      </Box>
      <ThemeProvider theme={darkTheme}>
        <Dialog fullScreen open={open} ba>
          <Box position="absolute" zIndex={100} right={16} top={16}>
            <IconButton onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </Box>
          <Stack justifyContent="space-between" flex={1}>
            <List sx={{ p: 4, mt: 4 }}>
              {headerNavLinks.map(({ name, link }) => (
                <ListItem key={name}>
                  <MuiLink fontSize={24} href={link} onClick={() => setOpen(false)}>
                    {name}
                  </MuiLink>
                </ListItem>
              ))}
              <ListItem>
                <MuiLink fontSize={24} href="/login" onClick={() => setOpen(false)}>
                  Log In
                </MuiLink>
              </ListItem>
            </List>
            <List sx={{ p: 4, borderTop: '1px solid' }}>
              <ListItem>
                <MuiLink href="/privacy-policy" onClick={() => setOpen(false)}>
                  Privacy Policy
                </MuiLink>
              </ListItem>
              <ListItem>
                <MuiLink href="/terms-of-service" onClick={() => setOpen(false)}>
                  Terms and Conditions
                </MuiLink>
              </ListItem>
              <ListItem>© 2023 Dailyduck.io</ListItem>
            </List>
          </Stack>
        </Dialog>
      </ThemeProvider>
    </>
  )
}
