export const addLunchPeriod = () => {
  const elements = document.getElementsByClassName('fc-timegrid-now-indicator-container')
  for (const el of elements) {
    const lunch_time = document.createElement('div')
    lunch_time.classList.add('lunch-time')
    if (!el.hasChildNodes()) {
      el.appendChild(lunch_time)
    }
  }
}
