import React, { useState } from 'react'
import { Grid, Skeleton, useMediaQuery } from '@mui/material'
import {
  XAxis,
  YAxis,
  Tooltip,
  Area,
  AreaChart,
  ResponsiveContainer,
  CartesianGrid
} from 'recharts'
import format from 'date-fns/format'

import { BENCHMARKS, METRICS_TYPES, PERIODS, PERIODS_OPTIONS } from 'metrics/constants'
import { PeriodToggler } from 'components/MetricChart/PeriodToggler'
import { TypographyText } from 'components/Typography'
import { ChartTooltip } from 'components/ChartTooltip'
import { ChartLegend } from 'components/ChartLegend'
import { useDate } from 'Date/DateContext'
import { useSortEventsByDates } from 'hooks/useSortEventsByDates'
import { useCountMetrics } from 'hooks/useCountMetrics'

import 'components/MetricChart/area-chart.scss'

export const MetricChart = ({ isLoading, events }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const [activePeriod, setActivePeriod] = useState(PERIODS.QUARTER)
  const { activeMetric } = useDate()
  const { aggregationPeriod, formatDate } = PERIODS_OPTIONS.find(
    (period) => period.value === activePeriod
  )
  const sortedEvents = useSortEventsByDates(events, activePeriod)
  const metricsValues = useCountMetrics(sortedEvents, aggregationPeriod)

  const chartData = {
    period: `${format(metricsValues[0]?.day, 'MMM d, y')} - ${format(
      metricsValues[metricsValues.length - 1]?.day,
      'MM d, y'
    )}`,
    data: metricsValues.map((o) => ({
      ...o,
      benchmarks: BENCHMARKS,
      day: format(o.day, formatDate),
      date: o.day
    }))
  }

  return (
    <div className="chart-container">
      <Grid container justifyContent="space-between" alignItems="top">
        <Grid item>
          {isLoading ? (
            <Skeleton variant="text" width={200} height={20} />
          ) : (
            <TypographyText noWrap weight={600}>
              {METRICS_TYPES.find((o) => o.value === activeMetric).name}
            </TypographyText>
          )}
          {!isLoading && <br />}
          {isLoading ? (
            <Skeleton variant="text" width={150} height={15} />
          ) : (
            <TypographyText noWrap size={12} weight={600} sx={{ color: '#B3B6C4' }}>
              {chartData.period}
            </TypographyText>
          )}
        </Grid>
        {matches && (
          <Grid item>
            {isLoading ? (
              <Skeleton variant="text" width={100} />
            ) : (
              <Grid item container direction="column">
                <ChartLegend name="My Stats" color="#3751FF" />
                <ChartLegend name="Benchmark" color="#f99245" />
              </Grid>
            )}
          </Grid>
        )}
        {matches && (
          <Grid item>
            {isLoading ? (
              <Skeleton width={150} />
            ) : (
              <PeriodToggler activePeriod={activePeriod} onChange={setActivePeriod} />
            )}
          </Grid>
        )}
      </Grid>
      {isLoading ? (
        <>
          <Skeleton height={10} sx={{ marginTop: '100px' }} />
          <Skeleton height={10} sx={{ marginTop: '30px' }} />
          <Skeleton height={10} sx={{ marginTop: '30px' }} />
          <Skeleton height={10} sx={{ marginTop: '30px' }} />
          <Skeleton height={10} sx={{ marginTop: '30px' }} />
          <Skeleton height={10} sx={{ marginTop: '30px' }} />
          <Skeleton height={10} sx={{ marginTop: '30px' }} />
        </>
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={chartData.data} margin={{ top: 35, bottom: 20 }}>
            <defs>
              <linearGradient id="color" x1="-0.09%" y1="50.09%" x2="100.09%" y2="49.91%">
                <stop offset=".015" stopColor="#3751FF" stopOpacity="0.1" />
                <stop offset="1.107" stopColor="#3751ff" stopOpacity="0" />
              </linearGradient>
            </defs>
            <XAxis dataKey="day" axisLine={false} tickLine={false} />
            <YAxis
              dataKey={activeMetric}
              allowDecimals={false}
              axisLine={false}
              tickLine={false}
              orientation="right"
              tick={{ dy: 0, dx: 0 }}
            />
            <CartesianGrid vertical={false} />
            <Tooltip content={<ChartTooltip period={activePeriod} />} />
            <Area
              type="monotone"
              dataKey={activeMetric}
              fillOpacity={1}
              strokeWidth={2}
              stroke="#445cff"
              fill="url(#color)"
            />
            <Area
              type="monotone"
              dataKey={`benchmarks.${activeMetric}.${activePeriod}`}
              fillOpacity={1}
              strokeWidth={2}
              stroke="#f99245"
              fill="none"
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </div>
  )
}
