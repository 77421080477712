import focusTimeNudge from '../assets/images/focus.svg'
import amountPerDayNudge from '../assets/images/bomb.svg'
import noPauseNudge from '../assets/images/bowling.svg'
import workingDayNudge from '../assets/images/volcano.svg'
import lunchNudge from '../assets/images/lunch.svg'
import overlaysNudge from '../assets/images/pancakes.svg'
import React from 'react'

export const NUDGES = {
  NO_FOCUS_TIME: 'no_focus_time',
  MEETINGS_AMOUNT: 'meetings_amount',
  MEETINGS_IN_A_ROW: 'meetings_row',
  MEETINGS_OVERLOAD: 'meetings_overload',
  DAY_WITHOUT_LUNCH: 'no_lunch',
  OVERLAYS: 'overlays'
}

export const NUDGES_TYPES = [
  {
    value: NUDGES.NO_FOCUS_TIME,
    name: 'No Focus time days',
    icon: <img src={focusTimeNudge} />,
    tooltip: 'Days you had no time for focus — at least 3 hour per day for concentrated work',
    priority: 6
  },
  {
    value: NUDGES.MEETINGS_AMOUNT,
    name: 'Over 7 meetings per day',
    icon: <img src={amountPerDayNudge} />,
    tooltip: 'Such amount of meetings make you unfocused and leads to "zoom fatique',
    priority: 5
  },
  {
    value: NUDGES.MEETINGS_IN_A_ROW,
    name: 'Events in a row',
    icon: <img src={noPauseNudge} />,
    tooltip:
      'You always need a break before meetings to do a short exercise and get ready for a new topic',
    priority: 4
  },
  {
    value: NUDGES.MEETINGS_OVERLOAD,
    name: 'Working day over 8 hours',
    icon: <img src={workingDayNudge} />,
    tooltip:
      'The time between the first and the last work event is more than 8 hours. This breaks your work-life balance and leads to burnout',
    priority: 3
  },
  {
    value: NUDGES.DAY_WITHOUT_LUNCH,
    name: 'Missed lunches',
    icon: <img src={lunchNudge} />,
    tooltip:
      'You have no "lunch placeholder" during typical lunch break time. Protect your lunch to remain healthy',
    priority: 2
  },
  {
    value: NUDGES.OVERLAYS,
    name: 'Meetings overlays',
    icon: <img src={overlaysNudge} />,
    tooltip:
      'Jumping between events is not the most effective strategy. Try to choose the things that are really important to you',
    priority: 1
  }
]
