import React from 'react'
import { isEmpty } from 'lodash'
import differenceInHours from 'date-fns/differenceInHours'
import { Stack } from '@mui/material'

import { NudgeCard } from './NudgeCard'
import { useNudges } from './hooks'

export const Nudges = ({
  events,
  activePeriod,
  activeNudge,
  setActiveNudge,
  setHighlightedDays,
  isLoading
}) => {
  const { nudgesValues, nudgesDaily } = useNudges(events, activePeriod)

  return (
    <>
      {!isEmpty(nudgesValues) && differenceInHours(activePeriod.end, activePeriod.start) !== 24 && (
        <>
          <Stack direction="row" spacing={2} mb={4} mt={2} sx={{ overflowX: 'auto' }}>
            {nudgesValues.map((nudge) => (
              <NudgeCard
                key={nudge}
                type={nudge}
                isLoading={isLoading}
                isActive={activeNudge === nudge}
                onActivate={() => {
                  if (activeNudge === nudge) {
                    setActiveNudge('')
                    setHighlightedDays([])
                  } else {
                    setActiveNudge(nudge)
                    setHighlightedDays(nudgesDaily.filter((o) => !!o[nudge]).map(({ day }) => day))
                  }
                }}
              />
            ))}
          </Stack>
        </>
      )}
    </>
  )
}
