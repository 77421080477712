import React from 'react'
import { useTheme } from '@mui/styles'
import { Stack, Radio, Paper } from '@mui/material'

import { TypographyText } from 'components'

export const RadioDirectionSync = ({ syncType, setSyncType }) => {
  const theme = useTheme()

  const handleChange = (event) => {
    setSyncType(event.target.value)
  }

  return (
    <>
      <TypographyText>How would you like to sync calendars?</TypographyText>
      <Paper variant="outlined" square={false} sx={{ width: '100%', overflow: 'hidden' }}>
        <Stack alignItems="start">
          <Stack
            direction="row"
            sx={{
              width: '100%',
              padding: 1,
              alignItems: 'end',
              backgroundColor: syncType === '1' ? theme.palette.primary.light : 'transparent'
            }}>
            <Radio
              sx={{ padding: '12px' }}
              checked={syncType === '1'}
              onChange={handleChange}
              value="1"
            />
            <Stack spacing={1}>
              <TypographyText size={14}>one-way</TypographyText>
              <TypographyText
                color={syncType === '1' ? theme.palette.primary.main : 'lightgray'}
                size={12}>
                Copy events from a source calendar to a target calendar each 5 minutes
              </TypographyText>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            sx={{
              width: '100%',
              padding: 1,
              alignItems: 'end',
              backgroundColor: syncType === '2' ? theme.palette.primary.light : 'transparent'
            }}>
            <Radio
              sx={{ padding: '12px' }}
              checked={syncType === '2'}
              onChange={handleChange}
              value="2"
            />
            <Stack spacing={1}>
              <TypographyText size={14}>two-way</TypographyText>
              <TypographyText
                color={syncType === '2' ? theme.palette.primary.main : 'lightgray'}
                size={12}>
                Copy events between both calendars each 5 minutes
              </TypographyText>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </>
  )
}
