import React from 'react'
import styled from 'styled-components'

import { CanonicalHelmet } from 'components'

export const PrivacyPolicyPage = () => {
  return (
    <>
      <CanonicalHelmet />
      <Content>
        <h1>Privacy policy</h1>
        <h2>Last updated January 31th, 2021</h2>
        <span>
          We at Dailydick.io (“Service”, “We”, “Us”) take your privacy seriously and are transparent
          about how we use it. By using or accessing the Services in any manner, you acknowledge
          that you accept the practices and policies outlined in this Privacy Policy, and you hereby
          consent that we will collect, use, and share your information in the following ways.
        </span>
        <h3>Data collection & Information you provide to us</h3>
        <span>
          We receive and store any information that you knowingly and voluntarily provide to us or
          allow us to use. If you access the Services through a third party site or service, you
          understand that some of the content and/or information in those accounts may be
          transmitted to and used by the Service. We may communicate with you if you’ve provided us
          the means to do so.
          <br />
          <br />
          <>
            DailyDuck uses and transfers to any other app of information received from Google APIs
            will adhere to{' '}
            <a
              href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
              target="_blank"
              rel="noreferrer">
              Google API Services User Data Policy
            </a>
            , including the Limited Use requirements.
          </>
        </span>
        <h3>Information Collected Automatically</h3>
        <span>
          Whenever you interact with our Services, we automatically receive and record information
          on our server logs from your browser or device, which may include your IP address,
          geolocation data, device identification, “cookie” information, the type of browser and/or
          device you’re using to access our Services, and the page or feature you requested. We
          aren’t responsible for any other websites or services’ privacy policies and practices.
        </span>
        <h3>Sharing Information</h3>
        <span>
          We will never disclose the data we collect from you to third parties unless it is for the
          delivery of the service.
        </span>
        <h3>Information Security</h3>
        <span>
          For your privacy and security, your account is protected by authentication through the
          calendar provider. We do not store or have access to your calendar provider password.
          Unauthorized access to your account is directly related to the security of your calendar
          provider account. You are responsible for protecting your account and personal information
          by properly securing your calendar account login mechanism and by restricting access to
          your computer or device and browser by logging out after you have completed accessing your
          account. We are committed to protecting the privacy of your account and other personal
          information we hold on our records, but no one can guarantee complete security.
        </span>
        <h3>How can I access my information?</h3>
        <span>
          You can send us a request to access, correct or delete any personal information that you
          have provided to us. We cannot delete your personal information except by also deleting
          your user account. We may not accommodate a request to change information if we believe
          the change would violate any law or legal requirement or cause the information to be
          incorrect.
        </span>
        <h3>Children under the age of 16</h3>
        <span>
          If you are under 16, please do not attempt to register for the Services or send any
          personal information about yourself to us. If we learn that we have collected personal
          information from a child under age 16, we will delete that information as quickly as
          possible.
        </span>
        <h3>Changes to our Privacy Policy</h3>
        <span>
          You are responsible for periodically reviewing this privacy policy to check for any
          changes.
        </span>
        <h3>If you have questions about this policy</h3>
        <span>
          If you have any questions or concerns regarding our privacy policies, please send us a
          detailed message to <a>a.yudovin6695@gmail.com</a>, and we will try to resolve your
          concerns.
        </span>
      </Content>
    </>
  )
}

const Content = styled.div`
  max-width: 960px;
  margin: 60px auto;
  padding: 40px 20px;

  span {
    font-size: 18px;
    a {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  h2 {
    font-size: 20px;
    margin: 10px 0;
  }
  h3 {
    font-size: 22px;
    margin: 60px 0 10px 0;
  }
`
