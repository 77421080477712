import React, { useEffect, useMemo, useRef, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import format from 'date-fns/format'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import { CircularProgress } from '@mui/material'

import { useDate } from 'Date/DateContext'
import { useGetEvents } from 'hooks/useGetEvents'
import { highlightOverlaysAndNoBreaks } from 'nudges/nudges'
import { StyledCalendar } from './styled'
import { addLunchPeriod } from './utils'
import 'components/Calendar/calendar.scss'

const headerToolbar = {
  start: 'prev,today,next,title',
  center: '',
  end: 'timeGridWeek,timeGridDay'
}

const dayHeaderContent = (info) => {
  const date = format(info.date, 'd ccc').split(' ')
  return (
    <div className="day-header">
      <div>{date[1].toUpperCase()}</div>
      <div>{date[0]}</div>
    </div>
  )
}

const customEventContent = (info) => (
  <div className="event">
    <div className="event-title">{info.event._def.title}</div>
    <div className="event-time">{info.timeText}</div>
  </div>
)

let prevState = []

export const MainCalendar = ({ children }) => {
  const [activePeriod, setActivePeriod] = useState([])
  const [highlightedDays, setHighlightedDays] = useState([])
  const [activeNudge, setActiveNudge] = useState('')

  const { events: data, isLoading } = useGetEvents({
    timeMin: activePeriod.start?.toISOString(),
    timeMax: activePeriod.end?.toISOString()
  })

  useEffect(() => {
    setActiveNudge('')

    if (data.length) {
      prevState = data
    }
  }, [data, setActiveNudge])

  const events = useMemo(() => {
    return data.length ? highlightOverlaysAndNoBreaks(data) : prevState
  }, [data])

  const { clickedDate } = useDate()
  const calendarRef = useRef(null)

  useEffect(() => {
    addLunchPeriod()
  }, [activePeriod])

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi()
    !!clickedDate && calendarApi.gotoDate(clickedDate.toISOString())
    addLunchPeriod()
  }, [clickedDate])

  const highlight = highlightedDays.map((day) => format(day, 'yyyy-MM-dd'))

  return (
    <>
      {children({
        events,
        activePeriod,
        activeNudge,
        setActiveNudge,
        setHighlightedDays,
        isLoading
      })}
      <StyledCalendar
        highlight={highlight}
        activeNudge={activeNudge}
        className={`calendar calendar-main ${isLoading ? 'loading' : ''}`}>
        {isLoading && (
          <CircularProgress
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          />
        )}
        <FullCalendar
          ref={calendarRef}
          plugins={[timeGridPlugin]}
          initialView="timeGridWeek"
          scrollTime="10:00:00"
          events={events.map((event) => ({
            title: event.summary,
            start: event.start,
            end: event.end,
            backgroundColor: event.summary === 'busy' ? '#E3F07F' : '#D7DCFF',
            classNames: [
              !!event.overlay && 'overlay',
              !!event.noBreak && 'noBreak',
              differenceInMinutes(event.end, event.start) < 20 && 'shortEvent'
            ]
          }))}
          eventContent={customEventContent}
          allDaySlot={false}
          dayHeaderContent={dayHeaderContent}
          height={window.innerHeight - 180}
          width={1080}
          nowIndicator={true}
          headerToolbar={headerToolbar}
          titleFormat={{ year: 'numeric', month: 'long', day: 'numeric' }}
          slotLabelFormat={(e) =>
            `${e.date.hour}:${e.date.minute <= 9 ? `0${e.date.minute}` : e.date.minute}`
          }
          datesSet={({ start, end }) => {
            const startDate = new Date(start)
            const endDate = new Date(end)
            setActivePeriod({ start: startDate, end: endDate })
          }}
        />
      </StyledCalendar>
    </>
  )
}
