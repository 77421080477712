import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  MenuItem,
  IconButton,
  Typography,
  useMediaQuery,
  Stack,
  Box,
  Toolbar,
  Menu as MuiMenu,
  ListItemIcon,
  ListItemText,
  Divider,
  Button
} from '@mui/material'
import { Menu, Logout, Payment, ChatOutlined, KeyboardArrowDown } from '@mui/icons-material'

import { useAuth } from 'auth/AuthContext'
import { useClearCache } from 'hooks/useClearCache'
import { TypographyText } from 'components/Typography'
import { CustomAvatar } from './styled'
import { routesLinks } from '../../constants'
import logo from 'assets/images/new_logo.svg'

export const PageHeader = ({ setIsExpandedDrawer }) => {
  const { user } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const clearCache = useClearCache()
  const matches = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box
      sx={{
        borderBottom: '2px solid #F2F2F7',
        backgroundColor: '#FFFFFF',
        color: '#191919'
      }}>
      <Toolbar sx={{ width: '100%', justifyContent: 'space-between' }}>
        <Box>
          <Stack direction="row" spacing={1} alignItems="center">
            {matches && <img alt="logo" src={logo} style={{ width: 32, height: 32 }} />}
            <Typography variant="h5" fontWeight={600}>
              {routesLinks.find(({ link }) => location.pathname.includes(link))?.name ||
                'Analytics'}
            </Typography>
          </Stack>
        </Box>
        <Box>
          {matches ? (
            <IconButton
              sx={{ color: '#191919' }}
              onClick={() => setIsExpandedDrawer((prev) => !prev)}>
              <Menu />
            </IconButton>
          ) : (
            <>
              <Button onClick={handleClick}>
                <CustomAvatar alt={user?.name} src={user?.picture} />
                <TypographyText size={14} weight={700} color="#191919" sx={{ marginLeft: '16px' }}>
                  {user?.name}
                </TypographyText>
                <KeyboardArrowDown sx={{ color: '#191919' }} />
              </Button>
              <MuiMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem
                  onClick={() => {
                    handleClose()
                    navigate('/billing')
                  }}>
                  <ListItemIcon>
                    <Payment fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Billing & Plans</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    handleClose()
                    navigate('/feedback')
                  }}>
                  <ListItemIcon>
                    <ChatOutlined fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Feedback</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose()
                    clearCache()
                  }}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Log Out</ListItemText>
                </MenuItem>
              </MuiMenu>
            </>
          )}
        </Box>
      </Toolbar>
    </Box>
  )
}
