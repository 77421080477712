import React from 'react'
import { Box, Paper, Skeleton, Stack } from '@mui/material'

export const PlanCardSkeleton = () => {
  return (
    <Paper
      variant="outlined"
      square={false}
      sx={{
        p: 3,
        flexGrow: 1,
        width: '100%',
        maxWidth: 360,
        height: 200
      }}>
      <Stack spacing={2}>
        <Box flex={1}>
          <Skeleton variant="rounded" width={180} />
        </Box>
        <Box flex={1}>
          <Skeleton variant="rounded" width={100} />
        </Box>
        <Box flex={1}>
          <Skeleton variant="rounded" width={150} />
        </Box>
        <Box flex={1}>
          <Skeleton variant="rounded" width={80} />
        </Box>
      </Stack>
    </Paper>
  )
}
