import React, { useState } from 'react'
import {
  Avatar,
  Button,
  IconButton,
  ListItemAvatar,
  ListItemText,
  ListItem,
  Chip,
  useMediaQuery,
  List,
  Box,
  Badge
} from '@mui/material'
import { iconButtonClasses } from '@mui/material/IconButton'
import { createStyles, makeStyles, useTheme } from '@mui/styles'
import { DeleteOutline, Error } from '@mui/icons-material'

import { useAuth } from 'auth/AuthContext'
import { ConfirmModal } from '../ConfirmModal/ConfirmModal'
import { useRemoveAccount, useAccountLogin } from 'hooks'

const useStyles = makeStyles(() =>
  createStyles({
    reauthentication: {
      animation: '$opacity 1s linear infinite'
    },
    '@keyframes opacity': {
      '0%': {
        opacity: 0.3
      },
      '50%': {
        opacity: 1
      },
      '100%': {
        opacity: 0.3
      }
    }
  })
)

export const AccountButton = ({ email, name, picture, main, isExpired = false }) => {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const { user } = useAuth()
  const { login, isLoading } = useAccountLogin()
  const { mutate: removeAccount, isLoading: isRemoving } = useRemoveAccount()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onRemoveAccount = async () => {
    await removeAccount({ source_email: user.email, calendar_email: email })
    setIsModalOpen(false)
  }

  return (
    <Button
      className={isLoading && classes.reauthentication}
      sx={{
        minWidth: 'auto',
        maxWidth: 320,
        opacity: isRemoving ? 0.5 : 1,
        pointerEvents: isRemoving ? 'none' : 'initial',
        '&:hover': {
          [`& .${iconButtonClasses.root}`]: {
            opacity: 1,
            transition: (theme) =>
              theme.transitions.create('all', {
                duration: theme.transitions.duration.short,
                easing: theme.transitions.easing.easeInOut
              })
          }
        }
      }}
      onClick={() => {
        if (isExpired) {
          login()
        }
      }}
      disabled={isRemoving || isLoading}>
      <List disablePadding sx={{ maxWidth: 304 }}>
        <ListItem disablePadding>
          <ListItemAvatar>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={isExpired ? <Error color="error" fontSize="small" /> : null}>
              <Avatar alt={name} src={picture} />
            </Badge>
          </ListItemAvatar>
          <ListItemText
            primary={name}
            secondary={email}
            primaryTypographyProps={{
              color: isExpired ? '#d32e2e' : '#191919'
            }}
          />
          <Box ml={1}>
            {main ? (
              <Chip label="main" variant="outlined" size="small" />
            ) : (
              <IconButton
                sx={{ width: 30, height: 30, opacity: matches ? 1 : 0 }}
                size="small"
                onClick={(e) => {
                  e.stopPropagation()
                  setIsModalOpen(true)
                }}>
                <DeleteOutline fontSize="small" />
              </IconButton>
            )}
          </Box>
        </ListItem>
      </List>
      {!main && (
        <>
          <ConfirmModal
            open={isModalOpen}
            setOpen={setIsModalOpen}
            onConfirm={onRemoveAccount}
            title="Remove account"
            text={
              <>
                Are you sure you want to remove{' '}
                <strong style={{ color: theme.palette.primary.main }}>{email}</strong> from Sync
                options?{' '}
                <strong style={{ color: theme.palette.primary.main }}>
                  All associated Syncs will also be removed
                </strong>
                .
              </>
            }
          />
        </>
      )}
    </Button>
  )
}
