import { styled } from '@mui/material/styles'

export const PageContent = styled('main')(() => ({
  overflowX: 'auto',
  scrollbarWidth: 'none', // Hide the scrollbar for firefox
  '&::-webkit-scrollbar': {
    display: 'none' // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
  },
  '&-ms-overflow-style:': {
    display: 'none' // Hide the scrollbar for IE
  },
  flexGrow: 1,
  padding: `30px 30px 20px`
}))
