import React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
  display: flex;
  justify-content: right;
  .cube {
    width: 8px;
    height: 8px;
    background-color: #000000;
    transform: rotate(45deg);
    margin: 0 5px;
    &-low {
      margin-top: 15px;
    }
    &-active {
      background-color: #3751ff;
    }
  }
`

export const CubesLines = ({ value, isActive }) => {
  const n = Number((value || 0).toFixed(0))
  const number = n > 5 ? 5 : n
  return (
    <StyledDiv>
      {[...Array(number)].map((_, i) => (
        <div key={i} className={`cube ${i % 2 ? 'cube-low' : ''} ${isActive && 'cube-active'}`} />
      ))}
    </StyledDiv>
  )
}
