import React from 'react'
import { Box, Paper, Stack, Divider } from '@mui/material'

import { TypographyText } from 'components'

export const SyncPreview = ({ syncType }) => {
  return (
    <Paper variant="outlined" square={false} sx={{ width: '100%', maxWidth: 350 }}>
      <Box sx={{ textAlign: 'center', padding: 0.5 }}>
        <TypographyText size={12} weight={900}>
          SYNC PREVIEW
        </TypographyText>
      </Box>
      <Box
        sx={{
          borderTop: '1px solid lightgray',
          borderBottom: '1px solid lightgray',
          padding: '4px 16px',
          backgroundColor: '#F2F2F2'
        }}>
        <TypographyText size={12}>
          {syncType === '1' ? 'Source Calendar' : 'Calendar 1'}
        </TypographyText>
      </Box>
      <Paper variant="outlined" square={false} sx={{ margin: 1 }}>
        <Stack>
          <Box sx={{ padding: 1, minHeight: 66 }}>
            <Box
              sx={{
                width: '100%',
                backgroundColor: '#049be5',
                height: '50px',
                borderRadius: 1,
                color: 'white',
                padding: 1
              }}>
              <Stack direction="column" justifyContent="space-between" spacing={1}>
                <TypographyText size={12} weight={600}>
                  Gym
                </TypographyText>
                <TypographyText size={12}>10:00 AM, Flex gym on 16th ave</TypographyText>
              </Stack>
            </Box>
          </Box>
          <Divider />
          <Box sx={{ padding: 1, minHeight: 66 }}>
            {syncType === '2' && (
              <Box
                sx={{
                  width: '100%',
                  backgroundColor: '#33b679',
                  height: '50px',
                  borderRadius: 1,
                  color: 'white',
                  padding: 1
                }}>
                <Stack direction="column" justifyContent="space-between" spacing={1}>
                  <TypographyText size={12} weight={600}>
                    Busy (Copy)
                  </TypographyText>
                  <TypographyText size={12}>-</TypographyText>
                </Stack>
              </Box>
            )}
          </Box>
        </Stack>
      </Paper>

      <Box
        sx={{
          borderTop: '1px solid lightgray',
          borderBottom: '1px solid lightgray',
          padding: '4px 16px',
          backgroundColor: '#F2F2F2'
        }}>
        <TypographyText size={12}>
          {syncType === '1' ? 'Target Calendar' : 'Calendar 2'}
        </TypographyText>
      </Box>
      <Paper variant="outlined" square={false} sx={{ margin: 1 }}>
        <Stack>
          <Box sx={{ padding: 1, minHeight: 66 }}>
            <Box
              sx={{
                width: '100%',
                backgroundColor: '#049be5',
                height: '50px',
                borderRadius: 1,
                color: 'white',
                padding: 1
              }}>
              <Stack direction="column" justifyContent="space-between" spacing={1}>
                <TypographyText size={12} weight={600}>
                  Busy (Copy)
                </TypographyText>
                <TypographyText size={12}>-</TypographyText>
              </Stack>
            </Box>
          </Box>
          <Divider />
          <Box sx={{ padding: 1, minHeight: 66 }}>
            <Box
              sx={{
                width: '100%',
                backgroundColor: '#33b679',
                height: '50px',
                borderRadius: 1,
                color: 'white',
                padding: 1
              }}>
              <Stack direction="column" justifyContent="space-between" spacing={1}>
                <TypographyText size={12} weight={600}>
                  Daily standup
                </TypographyText>
                <TypographyText size={12}>11:00 AM</TypographyText>
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Paper>
    </Paper>
  )
}
