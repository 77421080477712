import React from 'react'
import { Box, Grid, Paper, Skeleton } from '@mui/material'

export const PaymentItemSkeleton = () => {
  return (
    <Paper variant="outlined" square={false} sx={{ p: 3 }}>
      <Grid container spacing={{ xs: 1, sm: 0 }}>
        <Grid item xs={6} sm={3} display="flex" alignItems="center">
          <Box>
            <Skeleton variant="rounded" width={80} />
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          display="flex"
          alignItems="center"
          justifyContent={{ xs: 'end', sm: 'start' }}>
          <Box>
            <Skeleton variant="rounded" width={70} />
          </Box>
        </Grid>
        <Grid item xs={6} sm={3} display="flex" alignItems="center">
          <Box>
            <Skeleton variant="rounded" width={100} />
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          display="flex"
          alignItems="center"
          justifyContent={{ xs: 'end', sm: 'start' }}>
          <Box>
            <Skeleton variant="rounded" width={80} />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}
