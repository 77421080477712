import { useMutation, useQueryClient } from 'react-query'

import { useApi } from 'api/Api'

export const useRemoveAccount = () => {
  const { apiCloud } = useApi()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ source_email, calendar_email }) => {
      const results = await apiCloud.post('/remove_account_calendar', {
        source_email: source_email,
        calendar_email: calendar_email
      })

      return results.data
    },
    onSuccess: ({ calendar_accounts, errors: expired_accounts }) => {
      queryClient.setQueryData(['/show_account_calendars'], (prev) => {
        return {
          ...prev,
          calendar_accounts: prev.calendar_accounts.filter(({ email }) =>
            calendar_accounts.some((obj) => obj.email === email)
          ),
          expired_accounts: prev.expired_accounts.filter((prevEmail) =>
            expired_accounts.some((email) => prevEmail === email)
          )
        }
      })
      queryClient.setQueryData(['/get_background_syncs'], (prevSyncs) => {
        //  Remove all background syncs with deleted account
        return prevSyncs.filter(
          ({ sourceAccount, targetAccount }) =>
            !calendar_accounts.some(
              ({ email }) => sourceAccount !== email || targetAccount !== email
            )
        )
      })
    }
  })
}
