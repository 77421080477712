import React from 'react'
import { Box, Stack, Typography } from '@mui/material'

import b_1 from 'assets/images/b_1.svg'

const content = [
  {
    header: 'Privacy Focused',
    text: "We don't share, sell, or monetize any data. We don’t store any credit card details. We never see or store users' Google passwords. We only use necessary calendar permissions."
  },
  {
    header: 'Real-Time Synchronisation',
    text: "Your calendars are automatically updated in real-time every 5 minutes, so there's no need to worry about changes to the original events or the addition of new events."
  },
  {
    header: 'Personalised tips',
    text: 'We analyse your meeting statistics and provide valuable tips on how to improve productivity, prevent burnout, and reduce context switching'
  }
]

export const BenefitsSection = () => {
  return (
    <Box sx={{ width: '100%', backgroundColor: '#181d25', padding: '80px 0', color: '#fff' }}>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        alignItems="center"
        justifyContent="space-between"
        spacing={4}
        maxWidth={1300}
        padding="0 40px"
        margin="0 auto">
        <Stack spacing={4} alignItems="center" textAlign="center" maxWidth={600}>
          <Typography variant="h4" component="h2" fontWeight={700}>
            Effortlessly Sync Multiple Calendars
          </Typography>
          <Typography fontSize={18} color="#c6ced7">
            DailyDuck equips you with the only calendar software needed to effortlessly synchronize
            and manage multiple calendars.
          </Typography>
          <Box maxWidth={400}>
            <img src={b_1} style={{ width: '100%', transform: 'translateX(-32px)' }} />
          </Box>
        </Stack>
        <Box maxWidth={500}>
          <Stack spacing={4}>
            {content.map(({ header, text }, index) => (
              <Box key={text}>
                <Typography variant="h3" color="#3751ff">
                  0{index + 1}
                </Typography>
                <Typography variant="h5" fontWeight={700}>
                  {header}
                </Typography>
                <Typography fontSize={16} color="#c6ced7" mt={1}>
                  {text}
                </Typography>
              </Box>
            ))}
          </Stack>
        </Box>
      </Stack>
    </Box>
  )
}
