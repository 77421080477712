import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { Link, Stack } from '@mui/material'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import differenceInMinutes from 'date-fns/differenceInMinutes'

import { TypographyText } from 'components/Typography'
import { EventInfo, EventProgressTime } from './styled'

export const Event = ({ start, end, summary, hangoutLink }) => {
  const [now, setNow] = useState(new Date())

  useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 1000 * 60)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const isPast = isAfter(now, end)
  const isActive = isAfter(now, start) && isBefore(now, end)
  const eventLength = differenceInMinutes(end, start)
  const spentTime = differenceInMinutes(now, start)
  const progress = (spentTime * 100) / eventLength

  return (
    <EventInfo isPast={isPast} isActive={isActive}>
      <Stack spacing={1}>
        <TypographyText size={12} className="duration" color="#FFFFFF">
          {format(start, 'h:mm a')} - {format(end, 'h:mm a')}
        </TypographyText>
        <TypographyText size={12} color="#FFFFFF">
          {summary}
        </TypographyText>
        {hangoutLink && (
          <Link className="link" href={hangoutLink} target="_blank">
            {hangoutLink}
          </Link>
        )}
        {isActive && <EventProgressTime variant="determinate" value={progress} />}
      </Stack>
    </EventInfo>
  )
}
