import React from 'react'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'

export const MetricCardLinearProgress = ({ isActive, ...props }) => {
  return (
    <LinearProgress
      sx={{
        height: 6,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor: '#9FA2B4'
        },
        [`& .${linearProgressClasses.bar}`]: {
          transition: 'transform 1s cubic-bezier(.16,.58,.67,.98)',
          backgroundColor: isActive ? '#3751FF' : '#18181B'
        }
      }}
      {...props}
    />
  )
}
