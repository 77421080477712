import React, { useState } from 'react'
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  ThemeProvider,
  createTheme,
  Stack,
  useMediaQuery,
  Button,
  ListItemAvatar,
  Avatar,
  Collapse
} from '@mui/material'
import { ChevronRight, ChevronLeft, ExpandLess, ExpandMore, MenuOpen } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import { Drawer, SimpleDrawer, SubDrawer } from './styled'
import { SecondaryCalendar } from '../Calendar/SecondaryCalendar'
import { MetricColorPrompt } from '../MetricColorPrompt'
import { UpcomingEvents } from '../UpcomingEvents'
import { NavigationList } from './NavigationList'
import { useAuth } from 'auth/AuthContext'
import { useClearCache } from 'hooks/useClearCache'
import { useDrawers } from 'drawersContext/drawersContext'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#FFFFFF'
    },
    secondary: {
      main: '#3751FF'
    }
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#18181b',
          backgroundImage: 'none'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 48
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32
        }
      }
    }
  }
})

export const PageDrawer = ({ isExpanded, setIsExpanded }) => {
  const { user } = useAuth()
  const clearCache = useClearCache()
  const navigate = useNavigate()
  const matches = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const { isOpenSubDrawer, setIsOpenSubDrawer } = useDrawers()
  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [isExpandedMenu, setIsExpandedMenu] = useState(false)

  return (
    <ThemeProvider theme={darkTheme}>
      {matches ? (
        <>
          <SimpleDrawer open={isExpanded && !isOpenSubDrawer} anchor="left">
            <NavigationList
              isOpenSubDrawer={isOpenSubDrawer}
              setIsOpenSubDrawer={setIsOpenSubDrawer}
              setIsExpanded={setIsExpanded}
            />
            <List sx={{ marginTop: 'auto' }}>
              <ListItemButton onClick={() => setIsExpandedMenu((prev) => !prev)}>
                <ListItemAvatar>
                  <Avatar src={user.picture} />
                </ListItemAvatar>
                <ListItemText primary={user.name} />
                {isExpandedMenu ? <ExpandMore /> : <ExpandLess />}
              </ListItemButton>
              <Collapse in={isExpandedMenu}>
                <List component="div" disablePadding>
                  <ListItemButton
                    onClick={() => {
                      setIsExpanded(false)
                      navigate('/billing')
                    }}>
                    <ListItemText primary="Billing & Plans" primaryTypographyProps={{ pl: 7 }} />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      setIsExpanded(false)
                      navigate('/feedback')
                    }}>
                    <ListItemText primary="Feedback" primaryTypographyProps={{ pl: 7 }} />
                  </ListItemButton>
                  <ListItemButton onClick={clearCache}>
                    <ListItemText primary="Log Out" primaryTypographyProps={{ pl: 7 }} />
                  </ListItemButton>
                </List>
              </Collapse>
            </List>
          </SimpleDrawer>
          <SimpleDrawer open={isOpenSubDrawer} anchor="left" isSubDrawer={true}>
            <Box>
              <Button
                variant="outlined"
                startIcon={<ChevronLeft />}
                onClick={() => setIsOpenSubDrawer(false)}>
                Back
              </Button>
            </Box>
            <Divider sx={{ margin: '16px 0' }} />
            <Stack spacing={4}>
              <SecondaryCalendar setUpcomingEvents={setUpcomingEvents} />
              <MetricColorPrompt />
              <UpcomingEvents events={upcomingEvents} />
            </Stack>
          </SimpleDrawer>
        </>
      ) : (
        <Box>
          <Drawer open={isExpanded} variant="permanent" anchor="left">
            <NavigationList
              isOpenSubDrawer={isOpenSubDrawer}
              setIsOpenSubDrawer={setIsOpenSubDrawer}
              setIsExpanded={setIsExpanded}
            />
            <Box sx={{ marginTop: 'auto', padding: 1.5 }}>
              {isExpanded ? (
                <IconButton size="medium" onClick={() => setIsExpanded(false)}>
                  <MenuOpen fontSize="medium" />
                </IconButton>
              ) : (
                <IconButton size="medium" onClick={() => setIsExpanded(true)}>
                  <ChevronRight fontSize="medium" />
                </IconButton>
              )}
            </Box>
          </Drawer>
          <SubDrawer
            open={isOpenSubDrawer}
            variant="permanent"
            anchor="left"
            isExpandedDrawer={isExpanded}>
            <Stack spacing={4}>
              <SecondaryCalendar setUpcomingEvents={setUpcomingEvents} />
              <MetricColorPrompt />
              <UpcomingEvents events={upcomingEvents} />
            </Stack>
          </SubDrawer>
        </Box>
      )}
    </ThemeProvider>
  )
}
