import React from 'react'
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  CardMedia,
  Box,
  Stack
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { posts } from './consts'

export const BlogPage = () => {
  const navigate = useNavigate()

  return (
    <Box textAlign="center" p="40px 20px">
      <Typography variant="h3" component="h1" fontWeight={600}>
        The dailyduck.io Blog
      </Typography>
      <Typography variant="h6" mt={2}>
        All about productivity, work-life balance and how to avoid burnout
      </Typography>
      <Box sx={{ flexGrow: 1 }} maxWidth={1200} m="40px auto 0">
        <Grid container justifyContent="center" alignItems="stretch" spacing={4}>
          {posts.map((post) => (
            <Grid
              key={post.description}
              item
              xs={12}
              sm={6}
              md={4}
              sx={{ display: 'flex', justifyContent: 'center' }}
              onClick={(e) => {
                e.preventDefault()
                navigate(post.link)
              }}>
              <Card square={false} variant="outlined" sx={{ height: '100%', maxWidth: 410 }}>
                <Stack height="100%">
                  <CardContent>
                    <CardMedia
                      sx={{ height: 200, objectFit: 'fill' }}
                      component="img"
                      image={post.image}
                      alt=""
                    />
                    <Typography variant="h5" mt={2} fontWeight={700}>
                      {post.title}
                    </Typography>
                    <Typography fontSize={16} mt={1} color="#4e5357">
                      {post.description}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ mt: 'auto' }}>
                    <Typography variant="body2" color="text.secondary">
                      Read Time: {post.read}
                    </Typography>
                  </CardActions>
                </Stack>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  )
}
