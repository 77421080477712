import React from 'react'
import { Box, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material'
import { Check, Close } from '@mui/icons-material'

import { SelectPlanButton } from './SelectPlanButton'
import { PLANS_FEATURES, PLANS_PRICE } from '../../constants'

export const SelectPlanCard = ({ selectedPlan, setSelectedPlan, cardPlan, currentPlan }) => {
  return (
    <Paper variant="outlined" square={false} sx={{ flex: 1, p: 2 }}>
      <Box mb={2} textAlign="center">
        <Typography variant="h6" fontWeight={600} color="#181D25" sx={{ opacity: 0.6 }}>
          {cardPlan}
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h5" fontWeight={700}>
            ${PLANS_PRICE[cardPlan]}
          </Typography>
          <Typography sx={{ ml: 0.5 }}>/ month</Typography>
        </Box>
        <SelectPlanButton
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          currentPlan={currentPlan}
          planToCompare={cardPlan}
        />
      </Box>
      <List>
        {PLANS_FEATURES[cardPlan].map((feature, index, array) => (
          <ListItem
            key={feature.name}
            disablePadding
            sx={{
              borderBottom: index + 1 !== array.length ? '1px solid lightgray' : 'none',
              pt: 1,
              pb: 1
            }}>
            <ListItemIcon>{feature.checked ? <Check color="success" /> : <Close />}</ListItemIcon>
            <ListItemText primary={feature.name} />
          </ListItem>
        ))}
      </List>
    </Paper>
  )
}
