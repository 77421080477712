import styled from 'styled-components'
import { styled as MuiStyled } from '@mui/material/styles'
import React from 'react'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'

export const EventProgressTime = MuiStyled(({ ...props }) => <LinearProgress {...props} />)(() => ({
  height: 4,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#9FA2B4'
  },
  [`& .${linearProgressClasses.bar}`]: {
    transition: 'transform 1s cubic-bezier(.16,.58,.67,.98)',
    backgroundColor: '#3751FF'
  }
}))

export const EventInfo = styled.div`
  position: relative;

  .link {
    color: #a1a1aa;
    font-size: 12px;
    text-decoration: none;
  }

  ${(props) => props.isPast && !props.isActive && `opacity: .5`}
  ${(props) =>
    props.isActive &&
    `
    &:before {
      top: 1px;
      left: -16px;
      width: 8px;
      height: 8px;
      content: "";
      position: absolute;
      border-radius: 50%;
      background-color: #3751FF;
    }
  `}
`
