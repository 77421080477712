import React from 'react'
import styled from 'styled-components'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled as MuiStyled } from '@mui/material/styles'

export const MetricColorTooltip = MuiStyled(({ className, children, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }}>
    {children}
  </Tooltip>
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#2a2a2e'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#2a2a2e'
  }
}))

export const StyledTooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 6px;

  div {
    border-radius: 2px;
    padding: 6px 8px;
    font-size: 11px;
    font-weight: 600;

    &.green {
      background-color: #2f4636;
      color: #4ce364;
    }

    &.yellow {
      background-color: #464532;
      color: #e3dd4c;
    }

    &.red {
      background-color: #462f38;
      color: #e34c70;
    }
  }
`
