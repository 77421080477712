import React from 'react'
import styled from 'styled-components'

import { CanonicalHelmet } from 'components'

export const TermsOfServicePage = () => {
  return (
    <>
      <CanonicalHelmet />
      <Content>
        <h1>Terms of Service</h1>
        <h2>Last updated January 31th, 2021</h2>
        <span>
          These Terms of Service (“Terms”) govern your access and use of Dailyduck.io (the
          “Service”, “We”, “Website”), which can be accessed via URL (https://dailyduck.io). These
          Terms apply to all visitors, users and others who access or use the Service. Please read
          them carefully.
        </span>
        <h3>Important</h3>
        <span>
          by accessing or using the service you agree to be bound by these terms. if you disagree
          with any part of the terms then you may not access the service. the terms may be changed
          at any time and you must read these terms for any changes before using this website.
        </span>
        <h3>Links to other Websites</h3>
        <span>
          Our Service may contain links to external sites or services, that we are not responsible
          for the content, policies, or practices. You agree that We shall not be liable, directly
          or indirectly, for any damage or costs arising from any use of or reference to any such
          content, goods or services available. We strongly recommend you to read the terms and
          conditions and privacy policy of any application, website or service that you visit.
        </span>
        <h3>Disclaimer of Warranties</h3>
        <span>
          your use of the website is at your sole risk unless otherwise explicitly stated. neither
          dailyduck nor the individuals or entities directly or indirectly associated with it make
          any warranties with respect to any content contained in the services or accessible through
          the services, and we shall not be liable for the accuracy, copyright, legality or decency
          of the materials contained on the site or available through them. the services and content
          are provided on an `&quot;` basis without warranty of any kind, either express or implied,
          including but not limited to the implied warranties of merchantability, fitness for a
          particular purpose, non-infringement, or that the use of the services will be.
        </span>
        <h3>Limitation of Liability</h3>
        <span>
          To the maximum extent possible within the framework of the law, under no circumstances
          shall be liable for any damage, including incidental, direct or indirect, as well as for
          lost profits or subsequent circumstances that significantly affected the user of the
          Service or any individuals or legal entities directly related or indirectly with the user.
        </span>
        <h3>Contact Us</h3>
        <span>
          If you have any questions about these Terms, please contact us using{' '}
          <a>a.yudovin6695@gmail.com</a>
        </span>
      </Content>
    </>
  )
}

const Content = styled.div`
  max-width: 960px;
  margin: 60px auto;
  padding: 40px 20px;

  span {
    font-size: 18px;
    a {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  h2 {
    font-size: 20px;
    margin: 10px 0;
  }
  h3 {
    font-size: 22px;
    margin: 60px 0 10px 0;
  }
`
