import endOfDay from 'date-fns/endOfDay'
import startOfDay from 'date-fns/startOfDay'
import addHours from 'date-fns/addHours'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'

import { VOCABULARY } from '../assets/vocabulary/vocabulary'

export const countDailyEventLoad = (eventsInDay) => {
  const loadedTime = eventsInDay.reduce((acc, cur, i, arr) => {
    if (i !== arr.length - 1 && cur.end.getTime() > arr[i + 1].start.getTime()) {
      return acc + differenceInMinutes(arr[i + 1].start, cur.start)
    }
    return acc + differenceInMinutes(cur.end, cur.start)
  }, 0)
  return Number(loadedTime)
}

export const countDailyEventsWithoutPause = (events) => {
  const res = events.reduce((acc, cur, i, arr) => {
    let eventBreak = null
    if (i !== arr.length - 1) {
      eventBreak = differenceInMinutes(arr[i + 1].start, cur.end)
    }
    return eventBreak < 5 && eventBreak !== null ? acc + 1 : acc
  }, 0)
  return res === 0 ? res : res + 1
}

export const countDailyTimeEventsLoad = (events) => {
  const acceptedEvents = events.filter(
    ({ status, attendees }) =>
      (status === 'confirmed' || status === 'tentative') && attendees?.length > 1
  )
  return countDailyEventLoad(acceptedEvents) / 60
}

export const countDailyFocusTime = (events) => {
  if (!events.length) {
    return 0
  }
  const startDay = { end: addHours(startOfDay(events[0]?.start), 10) }
  const endDay = { start: addHours(startOfDay(events[0]?.start), 18) }
  const filteredEvents = events.filter(
    (event) =>
      isAfter(event.end, startDay.end) &&
      isBefore(event.start, endDay.start) &&
      event.attendees?.length > 1
  )
  const eventsWithLimits = [startDay, ...filteredEvents, endDay]
  const focusTimeInMinutes = eventsWithLimits.reduce((acc, cur, i, arr) => {
    let eventBreak
    if (i !== arr.length - 1) {
      eventBreak = differenceInMinutes(arr[i + 1].start, cur.end)
    }
    return eventBreak >= 90 ? acc + eventBreak : acc
  }, 0)
  return focusTimeInMinutes >= 180 ? 0 : 1
}

export const countMissedLunches = (events) => {
  const startLunchTime = addHours(startOfDay(events[0]?.start), 12)
  const endLunchTime = addHours(endOfDay(events[0]?.start), -9)
  const filteredEvents = events.filter(
    (event) => isAfter(event.end, startLunchTime) && isBefore(event.start, endLunchTime)
  )
  const eventsTitles = filteredEvents.map(({ summary }) => summary?.toLowerCase()).filter(Boolean)
  const isLunchToday = !!eventsTitles.filter((title) =>
    VOCABULARY.some((word) => title.split(' ').includes(word.toLowerCase()))
  ).length
  return isLunchToday ? 1 : 0
}
