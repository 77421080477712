import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

import {
  CalendarPage,
  WelcomePage,
  MetricsPage,
  PrivacyPolicyPage,
  TermsOfServicePage,
  LoginPage,
  CalendarSync,
  CreateBackgroundSync,
  PricingPage,
  Billing,
  BlogPage,
  TimeMastery,
  MaxProductivity,
  Feedback
} from 'pages'
import { useAuth } from 'auth/AuthContext'
import { PageLoader } from 'PageLoader/PageLoader'
import { PageLayout, LandingLayout } from 'components'
import { DrawersProvider } from 'drawersContext/drawersContext'

export const Routing = () => {
  const { isAuthenticated, initializing } = useAuth()
  const location = useLocation()

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname,
        email: JSON.parse(sessionStorage.getItem('primaryUser'))?.email ?? 'new user'
      })
    }
  }, [location])

  if (initializing) {
    return <PageLoader />
  }

  if (isAuthenticated) {
    return (
      <Routes>
        <Route
          path="/"
          element={
            <DrawersProvider>
              <PageLayout />
            </DrawersProvider>
          }>
          <Route index element={<CalendarSync />} />
          <Route path="calendar-sync" element={<CalendarSync />} />
          <Route path="analytics" element={<MetricsPage />} />
          <Route path="nudges" element={<CalendarPage />} />
          <Route path="calendar-sync/create" element={<CreateBackgroundSync />} />
          <Route path="billing" element={<Billing />} />
          <Route path="feedback" element={<Feedback />} />
          <Route path="*" element={<Navigate to="/calendar-sync" replace />} />
        </Route>
      </Routes>
    )
  }

  return (
    <Routes>
      <Route path="/" element={<LandingLayout />}>
        <Route index element={<WelcomePage />} />
        <Route exact path="/" element={<WelcomePage />} />
        <Route exact path="pricing" element={<PricingPage />} />
        <Route exact path="privacy-policy" element={<PrivacyPolicyPage />} />
        <Route exact path="terms-of-service" element={<TermsOfServicePage />} />
        <Route path="blog">
          <Route index element={<BlogPage />} />
          <Route path="time-mastery" element={<TimeMastery />} />
          <Route path="max-productivity" element={<MaxProductivity />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
      <Route exact path="login" element={<LoginPage />} />
    </Routes>
  )
}
