import { useMemo } from 'react'
import { countMissedLunches } from '../metrics/metrics'
import {
  countFocusTime,
  countMeetingsAmount,
  countMeetingsOverload,
  countMeetingsRow,
  countOverlays
} from '../nudges/nudges'
import differenceInHours from 'date-fns/differenceInHours'

export const useCountNudges = (eventsInActivePeriod = []) => {
  const firstEvent = eventsInActivePeriod[0]?.day
  const lastEvent = eventsInActivePeriod[eventsInActivePeriod.length - 1]?.day

  return useMemo(() => {
    if (differenceInHours(lastEvent, firstEvent) <= 24) {
      return []
    }
    return eventsInActivePeriod.map(({ day, events }) => {
      const noFocusTime = countFocusTime(events)
      const meetingsAmount = countMeetingsAmount(events)
      const meetingsRow = countMeetingsRow(events)
      const meetingsOverload = countMeetingsOverload(events)
      const missedLunch = !countMissedLunches(events)
      const overlays = countOverlays(events)

      return {
        day,
        no_focus_time: noFocusTime,
        meetings_amount: meetingsAmount,
        meetings_row: meetingsRow,
        meetings_overload: meetingsOverload,
        no_lunch: missedLunch,
        overlays: overlays
      }
    })
  }, [eventsInActivePeriod])
}
