import React from 'react'
import { Box, Stack, Typography } from '@mui/material'

import a_1 from 'assets/images/a_1.svg'
import a_2 from 'assets/images/a_2.svg'
import a_3 from 'assets/images/a_3.svg'
import a_4 from 'assets/images/a_4.svg'

const content = [
  {
    header: 'Balanced Workdays',
    text: 'Optimise your productivity by maintaining a balanced working day, preventing burnout and enhancing overall efficiency.',
    image: a_1
  },
  {
    header: 'Meeting Momentum',
    text: 'Boost focus and engagement by incorporating short pauses between meetings, ensuring a seamless and productive workflow.',
    image: a_2
  },
  {
    header: 'Missed lunches',
    text: 'Ensure your well-being by scheduling regular lunch breaks, promoting a healthier work-life balance.',
    image: a_3
  },
  {
    header: 'Meeting Mastery',
    text: 'Manage your schedule strategically to maximize meeting effectiveness and minimize time spent in unproductive calls.',
    image: a_4
  }
]

export const AnalyticsSection = () => {
  return (
    <Box sx={{ padding: '96px 0', backgroundColor: '#f9fafb' }}>
      <Typography variant="h4" component="h2" fontWeight={700} textAlign="center" padding="0 16px">
        Empower your schedule with meaningful statistics
      </Typography>
      <Stack spacing={8} maxWidth={1024} margin="80px auto 0" padding="0 16px">
        {content.map(({ header, text, image }, index) => (
          <Stack
            key={text}
            direction={{ xs: 'column', md: index % 2 ? 'row' : 'row-reverse' }}
            justifyContent="space-between"
            alignItems="center"
            spacing={5}>
            <Box maxWidth={500}>
              <Typography variant="h5" fontWeight={700}>
                {header}
              </Typography>
              <Typography fontSize={18} color="#6b7280" mt={2}>
                {text}
              </Typography>
            </Box>
            <Box
              maxWidth={400}
              display="flex"
              alignItems="center"
              justifyContent={index % 2 ? 'end' : 'start'}
              flex={1}>
              <img src={image} style={{ width: '100%' }} />
            </Box>
          </Stack>
        ))}
      </Stack>
    </Box>
  )
}
