import React from 'react'
import { IconButton } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'

import { NUDGES_TYPES } from 'nudges/constants'
import { MetricTooltip } from 'components/MetricTooltip'
import { CustomNudgeCard } from './styled'

export const NudgeCard = ({ type, onActivate, isActive, isLoading }) => {
  const nudge = NUDGES_TYPES.find(({ value }) => value === type)

  return (
    <CustomNudgeCard isActive={isActive} isLoading={isLoading} onClick={onActivate}>
      {nudge.icon}
      {nudge.name}
      <MetricTooltip title={nudge.tooltip} placement="top" arrow>
        <IconButton size="small">
          <InfoOutlined color={isActive ? 'primary' : 'black'} sx={{ fontSize: 16 }} />
        </IconButton>
      </MetricTooltip>
    </CustomNudgeCard>
  )
}
