import React from 'react'
import { Box, Button, Chip, Grid, Paper, Stack, Typography, useMediaQuery } from '@mui/material'

import { useAuth } from 'auth/AuthContext'
import { PaymentItemSkeleton } from './PaymentItemSkeleton'
import { paymentIntentStatuses } from './consts'
import { useGetCustomerPaymentHistory } from './hooks'
import no_payment_history from 'assets/images/payment_history.svg'

export const PaymentHistory = ({ setIsOpenPlanSelection }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const { user } = useAuth()
  const { data: paymentsHistory, isLoading } = useGetCustomerPaymentHistory(user.email)

  return (
    <Stack spacing={2}>
      {isLoading ? (
        <>
          <PaymentItemSkeleton />
          <PaymentItemSkeleton />
          <PaymentItemSkeleton />
        </>
      ) : (
        <>
          {paymentsHistory.data.length === 0 ? (
            <Box>
              <Stack alignItems="center" spacing={2} mt={4}>
                <img
                  alt="No syncs yet"
                  src={no_payment_history}
                  style={{ width: 250, height: 250 }}
                />
                <Typography fontSize={16}>Your payment history is empty</Typography>
                <Button variant="outlined" onClick={() => setIsOpenPlanSelection(true)}>
                  Change Plan
                </Button>
              </Stack>
            </Box>
          ) : (
            <>
              {!matches && (
                <Box
                  display="flex"
                  borderRadius={2}
                  sx={{ backgroundColor: '#3751ff0a', p: '12px 24px' }}>
                  <Box flex={1}>
                    <Typography>Amount</Typography>
                  </Box>
                  <Box flex={1}>
                    <Typography>Status</Typography>
                  </Box>
                  <Box flex={1}>
                    <Typography>Date</Typography>
                  </Box>
                  <Box flex={1}>
                    <Typography>Payment method</Typography>
                  </Box>
                </Box>
              )}
              {paymentsHistory.data.map((payment) => (
                <Paper
                  key={payment.id}
                  variant="outlined"
                  square={false}
                  sx={{ width: '100%', p: 3 }}>
                  <Grid container spacing={{ xs: 1, sm: 0 }}>
                    <Grid item xs={6} sm={3} display="flex" alignItems="center">
                      <Box display="flex" gap={1} flex={1}>
                        <Typography>{(payment.amount / 100).toFixed(2)}</Typography>
                        <Typography color="#666666">{payment.currency.toUpperCase()}</Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={3}
                      display="flex"
                      alignItems="center"
                      justifyContent={{ xs: 'end', sm: 'start' }}>
                      <Box>
                        <Chip
                          sx={{ maxWidth: matches ? 'auto' : 100 }}
                          label={payment.status}
                          color={paymentIntentStatuses[payment.status] || 'primary'}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={3} display="flex" alignItems="center">
                      <Box flex={1}>
                        <Typography color="#666666">
                          {new Date(payment.created * 1000).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric'
                          })}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={3}
                      display="flex"
                      alignItems="center"
                      justifyContent={{ xs: 'end', sm: 'start' }}>
                      <Box display="flex" gap={1}>
                        {payment.payment_method?.card ? (
                          <>
                            <Typography color="#666666" sx={{ textTransform: 'capitalize' }}>
                              {payment.payment_method.card.brand}
                            </Typography>
                            <Typography>{payment.payment_method.card.last4}</Typography>
                          </>
                        ) : (
                          <>-</>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              ))}
            </>
          )}
        </>
      )}
    </Stack>
  )
}
