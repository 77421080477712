import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import { useNavigate } from 'react-router-dom'
import format from 'date-fns/format'
import isSameDay from 'date-fns/isSameDay'
import getDay from 'date-fns/getDay'
import { useDate } from 'Date/DateContext'
import { useGetEvents } from 'hooks/useGetEvents'
import { useSortEventsByDays } from 'hooks/useSortEventsByDays'
import { useDayColorValue } from 'hooks/useDayColorValue'
import {
  DAILY_LOAD,
  DAILY_MISSED_LUNCHES,
  DAILY_EVENTS_WITHOUT_PAUSE,
  METRICS,
  DAILY_DAYS_WITHOUT_FOCUS_TIME
} from 'metrics/constants'

import 'components/Calendar/calendar.scss'

const paintDayByEventLoad = (dailyLoad, props) => {
  const dayPayload = dailyLoad.find(({ day }) => isSameDay(day, props.date))?.payload
  const load = dayPayload > 8 ? 8 : dayPayload
  return DAILY_LOAD.find(({ value }) => value === load)
}

const paintDayByMissedLunches = (dailyLoad, props) => {
  const dayPayload = dailyLoad.find(({ day }) => isSameDay(day, props.date))?.payload
  return DAILY_MISSED_LUNCHES.find(({ value }) => value === dayPayload)
}

const paintDayByEventsWithoutPause = (dailyLoad, props) => {
  const dayPayload = dailyLoad.find(({ day }) => isSameDay(day, props.date))?.payload
  const load = dayPayload > 6 ? 6 : dayPayload
  return DAILY_EVENTS_WITHOUT_PAUSE.find(({ value }) => value === load)
}

const paintDayByFocusTime = (dailyLoad, props) => {
  const dayPayload = dailyLoad.find(({ day }) => isSameDay(day, props.date))?.payload
  return DAILY_DAYS_WITHOUT_FOCUS_TIME.find(({ value }) => value === dayPayload)
}

const headerToolbar = {
  start: 'prev',
  center: 'title',
  end: 'next'
}

const dayHeaderContent = (info) => {
  const isToday = getDay(info.date) === getDay(new Date())
  const date = format(info.date, 'd ccc').split(' ')
  return <span className={`day-header ${isToday && 'today'}`}>{date[1].toUpperCase()}</span>
}

export const SecondaryCalendar = ({ setUpcomingEvents }) => {
  const navigate = useNavigate()
  const { setClickedDate, activeMetric } = useDate()
  const [datesSet, setDatesSet] = useState()
  const calendarRef = useRef(null)
  const { events, isLoading } = useGetEvents(datesSet)
  const sortedEvents = useSortEventsByDays(events, datesSet?.timeMin, datesSet?.timeMax)
  const payload = useDayColorValue(sortedEvents)

  useEffect(() => {
    if (events.length) {
      const upcomingEvents = events.filter(({ start }) => isSameDay(new Date(), start))
      upcomingEvents.length && setUpcomingEvents(upcomingEvents)
    }
  }, [events, setUpcomingEvents])

  const handleDateClick = (info) => {
    navigate('/calendar')
    setClickedDate(info.date)
  }

  const colorFn = useMemo(() => {
    if (activeMetric === METRICS.EVENTS_LOAD) return paintDayByEventLoad
    if (activeMetric === METRICS.MISSED_LUNCHES) return paintDayByMissedLunches
    if (activeMetric === METRICS.EVENTS_WITHOUT_PAUSE) return paintDayByEventsWithoutPause
    if (activeMetric === METRICS.FOCUS_TIME) return paintDayByFocusTime
  }, [activeMetric])

  const dayCellContentCallback = useCallback(
    (props) => {
      let dayStyles
      dayStyles = colorFn(payload, props)
      return <div style={{ ...dayStyles }}>{props.dayNumberText}</div>
    },
    [colorFn, payload]
  )

  return (
    <div className={`calendar calendar-secondary ${isLoading && 'loading'}`}>
      <FullCalendar
        ref={calendarRef}
        selectable
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={headerToolbar}
        titleFormat={{ month: 'long' }}
        contentHeight={200}
        dateClick={handleDateClick}
        dayHeaderContent={dayHeaderContent}
        dayCellContent={dayCellContentCallback}
        datesSet={({ start, end }) => {
          setDatesSet({ timeMin: start, timeMax: end })
        }}
      />
    </div>
  )
}
