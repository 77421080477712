import { createTheme, responsiveFontSizes } from '@mui/material'

import MulishRegular from '../assets/fonts/mulish-v5-latin-regular.woff2'

const appTheme = createTheme({
  typography: {
    fontFamily: 'Mulish, sans-serif'
  },
  palette: {
    primary: {
      dark: '#263ce6',
      main: '#3751FF',
      light: '#eaebff'
    },
    black: {
      main: '#191919',
      contrastText: '#ffffff'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          fontWeight: 700,
          textTransform: 'none'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 700
        },
        colorSuccess: {
          backgroundColor: '#e5f9f8',
          color: '#3cc5a1',
          textTransform: 'capitalize'
        },
        colorWarning: {
          backgroundColor: '#fff5d9',
          color: '#ffc813',
          textTransform: 'capitalize'
        },
        colorError: {
          backgroundColor: '#FFEBEE',
          color: '#EF5350',
          textTransform: 'capitalize'
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.disablePadding === false && {
            padding: '8px 16px'
          })
        })
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: 600,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        },
        secondary: {
          color: '#666666',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          cursor: 'pointer'
        }
      }
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: 700
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 14,
          backgroundColor: '#18181b'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.square === false && {
            borderRadius: 8
          }),
          cursor: 'pointer'
        })
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          margin: '0 auto',
          maxWidth: 1100
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none'
        }
      }
    },
    transitions: {
      duration: {
        standard: 200
      },
      easing: {
        easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)'
      }
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Mulish';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: "local('MulishRegular'), url(${MulishRegular}) format('woff2')";
          unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
        }
      `
    }
  }
})

export const theme = responsiveFontSizes(appTheme)
