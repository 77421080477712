import React from 'react'
import { Avatar, ListItem, ListItemAvatar, Skeleton, Stack } from '@mui/material'

export const AccountButtonSkeleton = () => {
  return (
    <ListItem
      sx={{ border: '1px solid lightgray', borderRadius: 4, width: 280, padding: '12px 16px' }}>
      <ListItemAvatar>
        <Skeleton variant="circular">
          <Avatar alt="avatar" src={''} />
        </Skeleton>
      </ListItemAvatar>
      <Stack spacing={0.3}>
        <Skeleton variant="text" sx={{ height: '15px', width: '80px' }} />
        <Skeleton variant="text" sx={{ height: '15px', width: '120px' }} />
      </Stack>
    </ListItem>
  )
}
