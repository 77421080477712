import React, { useState } from 'react'
import { Box, Button, Link as MuiLink, Stack, useMediaQuery } from '@mui/material'

import { MobileNavMenu } from './MobileNavMenu'
import { headerNavLinks } from './consts'
import { useScrollToAnchor } from 'hooks'
import logo from 'assets/images/logo_dailyduck.svg'

export const Header = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  useScrollToAnchor()
  const [open, setOpen] = useState(false)

  return (
    <Box
      component="header"
      position="sticky"
      zIndex={(theme) => theme.zIndex.appBar}
      top={0}
      sx={{ backgroundColor: '#ffffff', p: '16px 0' }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        maxWidth={1240}
        margin="0 auto">
        <MuiLink href="/" display="inline-flex" flex={1} ml={2} maxWidth={{ xs: 125, md: 180 }}>
          <img src={logo} alt="" style={{ width: '100%' }} />
        </MuiLink>
        {matches ? (
          <MobileNavMenu open={open} setOpen={setOpen} />
        ) : (
          <Stack direction="row" alignItems="center" spacing={3} mr={2}>
            {headerNavLinks.map(({ name, link }) => (
              <MuiLink key={name} color="#101220" href={link}>
                {name}
              </MuiLink>
            ))}
            <Button variant="outlined" href="/login">
              Log In
            </Button>
          </Stack>
        )}
      </Stack>
    </Box>
  )
}
