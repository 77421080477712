import { useMemo } from 'react'
import startOfWeek from 'date-fns/startOfWeek'
import addWeeks from 'date-fns/addWeeks'
import startOfMonth from 'date-fns/startOfMonth'
import addMonths from 'date-fns/addMonths'
import addDays from 'date-fns/addDays'
import isSameWeek from 'date-fns/isSameWeek'
import isSameMonth from 'date-fns/isSameMonth'
import isAfter from 'date-fns/isAfter'
import isSameDay from 'date-fns/isSameDay'
import isBefore from 'date-fns/isBefore'
import endOfWeek from 'date-fns/endOfWeek'
import endOfMonth from 'date-fns/endOfMonth'

import { sortEventsByDays } from './useSortEventsByDays'
import { PERIODS } from '../metrics/constants'

const QuarterFirstDate = startOfWeek(addWeeks(new Date(), -12))
const YearFirstDate = startOfMonth(addMonths(new Date(), -12))
const lastQuarterDates = [
  ...Array(12)
    .fill(QuarterFirstDate)
    .map((o, i) => addDays(o, i * 7))
]
const lastYearDates = [
  ...Array(12)
    .fill(YearFirstDate)
    .map((o, i) => addMonths(o, i))
]

export const useSortEventsByDates = (events = [], periodType) => {
  return useMemo(() => {
    const endOfPeriod = periodType === PERIODS.QUARTER ? endOfWeek : endOfMonth
    const filterFn = periodType === PERIODS.QUARTER ? isSameWeek : isSameMonth
    const dates = periodType === PERIODS.QUARTER ? lastQuarterDates : lastYearDates
    const lastDate = periodType === PERIODS.QUARTER ? QuarterFirstDate : YearFirstDate

    const filteredEvents = events.filter(
      ({ start }) =>
        (isAfter(start, lastDate) || isSameDay(start, lastDate)) &&
        (isBefore(start, endOfPeriod(dates[11])) || isSameDay(start, endOfPeriod(dates[11])))
    )

    return dates.map((day) => ({
      day,
      events: sortEventsByDays(filteredEvents.filter((o) => filterFn(o.start, day)))
    }))
  }, [events, periodType])
}
