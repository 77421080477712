import React from 'react'
import { Button } from '@mui/material'

export const SelectPlanButton = ({ selectedPlan, setSelectedPlan, currentPlan, planToCompare }) => {
  return (
    <Button
      variant={selectedPlan === planToCompare ? 'contained' : 'outlined'}
      color={selectedPlan === planToCompare ? 'black' : 'primary'}
      disabled={currentPlan === planToCompare}
      onClick={() => setSelectedPlan(planToCompare)}
      sx={{ width: 150, mt: 2 }}>
      {selectedPlan === planToCompare && 'Selected'}
      {currentPlan === planToCompare && 'Current'}
      {currentPlan !== planToCompare && selectedPlan !== planToCompare && 'Select'}
    </Button>
  )
}
