import format from 'date-fns/format'
import addDays from 'date-fns/addDays'
import React from 'react'

import { TypographyText } from './Typography'
import { METRICS_TYPES, PERIODS } from '../metrics/constants'

export const ChartTooltip = ({ active, payload, period }) => {
  const date = payload[0]?.payload?.date
  const name = METRICS_TYPES.find(({ value }) => value === payload[0]?.dataKey)?.name
  const value = payload[0]?.value
  const benchmark = payload[1]?.value

  if (active && payload && payload.length) {
    return (
      <div className="tooltip">
        {period === PERIODS.QUARTER ? (
          <TypographyText size={12}>
            {format(date, 'dd.MM')} - {format(addDays(date, 6), 'dd.MM')}
          </TypographyText>
        ) : (
          <TypographyText size={12}>{format(date, 'MMM’ yy')}</TypographyText>
        )}
        <TypographyText size={12}>
          {name} : {value}
        </TypographyText>
        <TypographyText size={12}>benchmark: {benchmark}</TypographyText>
      </div>
    )
  }
  return null
}
