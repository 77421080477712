import React from 'react'
import { format } from 'date-fns'
import { Stack } from '@mui/material'

import { TypographyText } from 'components/Typography'
import { Event } from './Event'

export const UpcomingEvents = ({ events }) => (
  <div>
    {events.length ? (
      <>
        <TypographyText size={14} weight={700} color="#FFFFFF">
          TODAY {format(new Date(), 'MM/dd/yyyy')}
        </TypographyText>
        <Stack pl={2} mt={2} spacing={2}>
          {events.map((event) => (
            <Event key={event.start + event.end} {...event} />
          ))}
        </Stack>
      </>
    ) : (
      <TypographyText size={14} weight={700} color="#FFFFFF">
        No events for today
      </TypographyText>
    )}
  </div>
)
