import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Alert, AlertTitle, Box, Link as MuiLink, Stack, Typography } from '@mui/material'
import { AddCircle } from '@mui/icons-material'
import ReactGA from 'react-ga4'

import { TypographyText, CanonicalHelmet } from 'components'
import { useAuth } from 'auth/AuthContext'
import { Synchranizations } from './Synchranizations'
import { Accounts } from './Accounts'
import { useGetAccounts } from './hooks'
import { SUBSCRIPTION_PLANS } from '../../constants'

export const CalendarSync = () => {
  const { user } = useAuth()
  const { data: accounts = { calendar_accounts: [], expired_accounts: [] }, isLoading } =
    useGetAccounts(user.email)

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.event({
        category: 'Load page',
        action: 'loaded calendar-sync page',
        label: JSON.parse(sessionStorage.getItem('primaryUser')).email.replace('@', '_')
      })
    }
  }, [])

  return (
    <>
      <CanonicalHelmet />
      <Box sx={{ maxWidth: 1000, margin: '0 auto' }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <TypographyText size={18} weight={600}>
            Connected calendars
          </TypographyText>
        </Stack>

        <Accounts accounts={accounts} isLoading={isLoading} />

        <TypographyText size={18} weight={600}>
          Synchronizations
        </TypographyText>

        {!!accounts.expired_accounts.length && !isLoading && (
          <Box mt={2}>
            <Alert severity="error">
              <AlertTitle>Looks like one of your accounts is expired</AlertTitle>
              Please re-authenticate to your expired account to keep syncing your calendars.
            </Alert>
          </Box>
        )}

        {accounts.calendar_accounts.length + accounts.expired_accounts.length <= 1 &&
          !isLoading && (
            <Box mt={2}>
              <Alert severity="info">
                <AlertTitle>You should have at least 2 calendars to sync them</AlertTitle>
                <Typography display="inline-flex">
                  Please add one more calendar by clicking on a{' '}
                  <Box ml={1} mr={1}>
                    <AddCircle />
                  </Box>{' '}
                  button next to your main account area
                </Typography>
              </Alert>
            </Box>
          )}

        {user.plan === SUBSCRIPTION_PLANS.FREE &&
          accounts.calendar_accounts.length + accounts.expired_accounts.length > 1 && (
            <Box mt={2}>
              <Alert severity="info">
                <AlertTitle>You can not sync calendars with current subscription plan</AlertTitle>
                <Typography display="inline-flex">
                  Please
                  <MuiLink ml={0.5} mr={0.5} component={Link} underline="hover" to="/billing">
                    upgrade to Basic
                  </MuiLink>{' '}
                  subscription plan to sync calendars
                </Typography>
              </Alert>
            </Box>
          )}

        <Synchranizations accounts={accounts} isLoadingAccounts={isLoading} />
      </Box>
    </>
  )
}
