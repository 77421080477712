import React, { useState } from 'react'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Typography,
  Tooltip
} from '@mui/material'
import { ErrorOutline } from '@mui/icons-material'

import { useAuth } from 'auth/AuthContext'
import { PaymentHistory } from 'components'
import { PlanSelection } from './PlanSelection'
import { PlanCardSkeleton } from './PlanCardSkeleton'
import { convertFromTimestampToDate } from './utils'
import { SUBSCRIPTION_PLANS } from '../../constants'
import { useCreatePortalSession, useGetCustomerSubscriptionInfo } from './hooks'

import card_background from 'assets/images/layered-waves-haikei5.svg'

export const Billing = () => {
  const { user } = useAuth()
  const [isOpenPlanSelection, setIsOpenPlanSelection] = useState(false)

  const { data: subscription, isLoading } = useGetCustomerSubscriptionInfo(user.email)
  const { mutate: createPortalSession, isLoading: isLoadingPortalSession } = useCreatePortalSession(
    user.email
  )

  return (
    <Box sx={{ maxWidth: 800, margin: '0 auto' }}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems="center"
        justifyContent="space-between"
        spacing={2}>
        {isLoading ? (
          <>
            <PlanCardSkeleton />
            <PlanCardSkeleton />
          </>
        ) : (
          <>
            <Paper
              variant="outlined"
              square={false}
              sx={{
                p: 3,
                flexGrow: 1,
                width: '100%',
                maxWidth: 360,
                height: 200,
                color: '#ffffff',
                backgroundImage: `url(${card_background})`
              }}>
              <Stack height="100%" alignItems="start">
                <Typography>Current Subscription Plan</Typography>
                <Stack direction="row" spacing={2}>
                  <Typography
                    variant="h5"
                    fontWeight={600}
                    display="inline-flex"
                    alignItems="center">
                    $
                    {user.plan === SUBSCRIPTION_PLANS.FREE || subscription?.status === 'trialing'
                      ? '0'
                      : '5'}
                    {subscription?.status === 'trialing' && (
                      <Typography fontSize={14} ml={1}>
                        ($5 after trial ends)
                      </Typography>
                    )}
                  </Typography>
                </Stack>
                <Typography variant="subtitle1" display="inline-flex" alignItems="center">
                  {user.plan}
                  {subscription?.status === 'trialing' && (
                    <Chip
                      label="trial"
                      size="small"
                      sx={{ backgroundColor: 'white', color: '#3751ff', ml: 1 }}
                    />
                  )}
                </Typography>
                <Button
                  sx={{ mt: 'auto' }}
                  size="small"
                  variant="contained"
                  onClick={() => setIsOpenPlanSelection(true)}>
                  Change Plan
                </Button>
              </Stack>
            </Paper>
            <Paper
              variant="outlined"
              square={false}
              sx={{ p: 3, flexGrow: 1, width: '100%', maxWidth: 360, height: 200 }}>
              <Stack height="100%" alignItems="start">
                {subscription?.status === 'trialing' && !subscription?.cancel_at_period_end && (
                  <>
                    <Typography>Trial period will end on</Typography>
                    <strong>{convertFromTimestampToDate(subscription.trial_end)}</strong>
                  </>
                )}
                {subscription?.cancel_at_period_end && (
                  <>
                    <Typography>Subscription will be canceled at</Typography>
                    <strong>{convertFromTimestampToDate(subscription.current_period_end)}</strong>
                    <Typography mt={1}>
                      Contact us if you wish immediate cancellation and refund
                    </Typography>
                  </>
                )}
                {subscription?.status !== 'trialing' && !subscription?.cancel_at_period_end && (
                  <>
                    <Typography>Next Payment</Typography>
                    <Typography variant="h5" fontWeight={600}>
                      ${user.plan === SUBSCRIPTION_PLANS.FREE ? '0' : '5.00'}
                    </Typography>
                    <Typography variant="subtitle1">
                      {subscription && convertFromTimestampToDate(subscription.current_period_end)}
                    </Typography>
                  </>
                )}
                {subscription &&
                  !subscription.default_payment_method &&
                  !subscription.cancel_at_period_end &&
                  !subscription.customer.invoice_settings.default_payment_method && (
                    <Stack direction="row" alignItems="center" mt="auto">
                      <Typography fontSize={16}>Please set payment method</Typography>
                      <Tooltip
                        title="Click on the button below to set payment method. If payment method is not provided by the end of trial period or payment day, the subscription will be cancelled automatically"
                        arrow>
                        <IconButton size="small">
                          <ErrorOutline fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  )}
                <Button
                  sx={{ mt: 'auto' }}
                  color="black"
                  size="small"
                  variant="contained"
                  onClick={createPortalSession}
                  disabled={!subscription || isLoadingPortalSession}>
                  {isLoadingPortalSession ? <CircularProgress size={23} /> : 'Billing Details'}
                </Button>
              </Stack>
            </Paper>
          </>
        )}
      </Stack>
      <Box mt={4} mb={2}>
        <Typography variant="h6">Payment History</Typography>
      </Box>
      <PaymentHistory setIsOpenPlanSelection={setIsOpenPlanSelection} />
      <PlanSelection
        open={isOpenPlanSelection}
        setOpen={setIsOpenPlanSelection}
        currentSubscription={subscription}
      />
    </Box>
  )
}
