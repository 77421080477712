import { useMemo } from 'react'
import { METRICS } from '../metrics/constants'
import {
  countDailyEventsWithoutPause,
  countDailyFocusTime,
  countDailyTimeEventsLoad,
  countMissedLunches
} from '../metrics/metrics'
import { useDate } from '../Date/DateContext'

export const useDayColorValue = (events) => {
  const { activeMetric } = useDate()

  const counterFn = useMemo(() => {
    if (activeMetric === METRICS.EVENTS_LOAD) return countDailyTimeEventsLoad
    if (activeMetric === METRICS.MISSED_LUNCHES) return countMissedLunches
    if (activeMetric === METRICS.EVENTS_WITHOUT_PAUSE) return countDailyEventsWithoutPause
    if (activeMetric === METRICS.FOCUS_TIME) return countDailyFocusTime
  }, [activeMetric])

  return useMemo(() => {
    return events.map((o) => ({
      day: o.day,
      payload: o.events.length ? Number(counterFn(o.events.flat()).toFixed()) : null
    }))
  }, [events, counterFn])
}
