import React from 'react'
import { Grid, Skeleton, IconButton, Stack } from '@mui/material'

import { METRICS, METRICS_TYPES } from 'metrics/constants'
import { InfoOutlined } from '@mui/icons-material'
import { MetricTooltip } from 'components/MetricTooltip'
import { TypographyText } from 'components/Typography'

import 'components/MetricCard/metricCard.scss'
import { CubesLines } from 'components/MetricCard/CubesLines'
import { MetricCardLinearProgress } from 'components/MetricCardLinearProgress'
import { MetricCardCircularProgress } from 'components/MetricCardCircularProgress'
import { useDate } from 'Date/DateContext'

const MetricCardUpperPart = ({ metric, value, isLoading, isActive }) => {
  return (
    <div className="upper-part">
      {metric === METRICS.EVENTS_LOAD &&
        (isLoading ? (
          <Skeleton variant="circular" width={50} height={50} sx={{ marginLeft: 'auto' }} />
        ) : (
          <MetricCardCircularProgress
            isActive={isActive}
            variant="determinate"
            value={(value / 8) * 100}
          />
        ))}
      {metric === METRICS.EVENTS_WITHOUT_PAUSE &&
        (isLoading ? (
          <>
            <Skeleton variant="text" height={15} width={70} sx={{ marginLeft: 'auto' }} />
            <Skeleton variant="text" height={15} width={50} sx={{ marginLeft: 'auto' }} />
          </>
        ) : (
          <CubesLines value={value} isActive={isActive} />
        ))}
      {metric === METRICS.FOCUS_TIME &&
        (isLoading ? (
          <Skeleton variant="text" height={15} />
        ) : (
          <MetricCardLinearProgress
            isActive={isActive}
            variant="determinate"
            value={(value / 20) * 100}
          />
        ))}
      {metric === METRICS.MISSED_LUNCHES &&
        (isLoading ? (
          <>
            <Skeleton variant="text" height={15} width={70} sx={{ marginLeft: 'auto' }} />
            <Skeleton variant="text" height={15} width={50} sx={{ marginLeft: 'auto' }} />
          </>
        ) : (
          <CubesLines value={value} isActive={isActive} />
        ))}
    </div>
  )
}

export const MetricCard = ({ metric, value, isLoading }) => {
  const { activeMetric, setActiveMetric } = useDate()

  const { name, tooltip, unit } = METRICS_TYPES.find(({ value }) => value === metric)
  const isActive = activeMetric === metric
  const activeColor = isActive ? '#3751FF' : '#9FA2B4'

  return (
    <div
      className={`daily-metric ${isActive ? 'active' : ''} ${isLoading ? 'loading' : ''}`}
      onClick={() => setActiveMetric(metric)}>
      <Grid container direction="column">
        <Grid item xs>
          <MetricCardUpperPart
            metric={metric}
            value={value}
            isLoading={isLoading}
            isActive={isActive}
          />
        </Grid>
        {isLoading ? (
          <>
            <Skeleton variant="text" height={15} width={170} />
            <Skeleton variant="text" height={50} width={50} />
            <Skeleton variant="text" height={10} width={150} />
          </>
        ) : (
          <Stack>
            <TypographyText size={14} weight={600} className="metric-name">
              {name}
              <MetricTooltip title={tooltip} placement="top" arrow>
                <IconButton size="small" color="primary">
                  <InfoOutlined color={isActive ? 'primary' : 'black'} sx={{ fontSize: 16 }} />
                </IconButton>
              </MetricTooltip>
            </TypographyText>
            <TypographyText
              size={36}
              weight={600}
              sx={{ color: '#18181B', margin: '2px 0 5px' }}
              className="metric-value">
              {value}
              {unit}
            </TypographyText>
            <TypographyText size={12} sx={{ color: activeColor }}>
              {metric === METRICS.EVENTS_LOAD || metric === METRICS.EVENTS_WITHOUT_PAUSE
                ? 'Average over the last 4 weeks'
                : 'Total over the last 4 weeks'}
            </TypographyText>
          </Stack>
        )}
      </Grid>
    </div>
  )
}
