import React from 'react'

import { METRICS } from 'metrics/constants'
import { StyledTooltipContent } from './styled'

export const TooltipContent = ({ metric }) => (
  <StyledTooltipContent>
    <div className="green">
      {metric === METRICS.EVENTS_LOAD && '0 - 2 hours'}
      {metric === METRICS.EVENTS_WITHOUT_PAUSE && '0 - 2 meetings without pause'}
      {metric === METRICS.FOCUS_TIME && 'at least 3 hours'}
      {metric === METRICS.MISSED_LUNCHES && 'lunch today'}
    </div>
    {(metric === METRICS.EVENTS_LOAD || metric === METRICS.EVENTS_WITHOUT_PAUSE) && (
      <div className="yellow">
        {metric === METRICS.EVENTS_LOAD && '3 - 5 hours'}
        {metric === METRICS.EVENTS_WITHOUT_PAUSE && '3 - 4 meetings without pause'}
      </div>
    )}
    <div className="red">
      {metric === METRICS.EVENTS_LOAD && '6 - 8 hours'}
      {metric === METRICS.EVENTS_WITHOUT_PAUSE && '5 - 6 meetings without pause'}
      {metric === METRICS.FOCUS_TIME && 'less than 3 hours'}
      {metric === METRICS.MISSED_LUNCHES && 'missed lunch'}
    </div>
  </StyledTooltipContent>
)
