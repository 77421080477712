import { useMutation, useQueryClient } from 'react-query'
import { useApi } from 'api/Api'

export const useUnsubscribeFromBackgroundSyncer = () => {
  const queryClient = useQueryClient()
  const { apiCloud } = useApi()

  return useMutation({
    mutationFn: async ({ id, account_email }) => {
      const results = await apiCloud.post('/unsubscribe_from_background_syncer', {
        id,
        account_email
      })

      return results.data
    },
    onSuccess: ({ syncs }) => {
      queryClient.setQueryData(['/get_background_syncs'], () => {
        return syncs
      })
    }
  })
}

export const useToggleBackgroundSyncStatus = () => {
  const { apiCloud } = useApi()

  return useMutation({
    mutationFn: async ({ account_email, id, toggle }) => {
      const results = await apiCloud.post('/toggle_background_sync', {
        account_email: account_email,
        id: id,
        toggle: toggle
      })

      return results.data
    }
  })
}
