import { useMutation, useQuery } from 'react-query'

import { useApi } from 'api/Api'
import { useAuth } from 'auth/AuthContext'
import { SUBSCRIPTION_PLANS } from '../../constants'

export const useCreateCheckoutSession = (email) => {
  const { apiCloud } = useApi()

  return useMutation({
    mutationFn: async () => {
      try {
        const response = await apiCloud.post('/stripe_api/create_customer_checkout_session', {
          email: email
        })

        return response.data
      } catch (err) {
        console.log(err)
      }
    },
    onSuccess: ({ session }) => {
      window.location.replace(session.url)
    }
  })
}

export const useCreatePortalSession = (email, flow_type = null) => {
  const { apiCloud } = useApi()

  return useMutation({
    mutationFn: async () => {
      try {
        const response = await apiCloud.post('/stripe_api/create_customer_portal_session', {
          email: email,
          flow_type: flow_type
        })

        return response.data
      } catch (err) {
        console.log(err)
      }
    },
    onSuccess: ({ session }) => {
      window.location.replace(session.url)
    }
  })
}

export const useGetCustomerSubscriptionInfo = (email) => {
  const { apiCloud } = useApi()
  const { user, setUser } = useAuth()

  return useQuery(
    ['/stripe_api/get_customer_subscription_info'],
    async () => {
      try {
        const results = await apiCloud.post('/stripe_api/get_customer_subscription_info', {
          email: email
        })

        return results.data.subscription
      } catch (err) {
        console.log(err)
      }
    },
    {
      onSuccess: (subscription) => {
        const plan = subscription ? SUBSCRIPTION_PLANS.BASIC : SUBSCRIPTION_PLANS.FREE
        setUser({ ...user, plan })
        sessionStorage.setItem('primaryUser', JSON.stringify({ ...user, plan }))
      }
    }
  )
}
