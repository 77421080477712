import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'

export const ConfirmModal = ({ open, setOpen, onConfirm, title, text }) => {
  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = (e) => {
    e.stopPropagation()
    e.preventDefault()
    onConfirm()
    handleClose()
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            handleClose()
          }}
          variant="contained">
          Cancel
        </Button>
        <Button onClick={handleConfirm} variant="outlined">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
