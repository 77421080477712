export const VOCABULARY = [
  'lunch',
  'breakfast',
  'sup',
  'picnic',
  'snack',
  'banquet',
  'feast',
  'dine',
  'eat',
  'feed',
  'food',
  'coffee',
  'tea',
  'breakfast',
  'buffet',
  'dinner',
  'luncheon',
  'supper',
  'spread',
  'bake',
  'barbecue',
  'barbeque',
  'cookout',
  'roast'
]
