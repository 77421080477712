import { useMemo } from 'react'
import addDays from 'date-fns/addDays'
import differentInDays from 'date-fns/differenceInDays'
import isWeekend from 'date-fns/isWeekend'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import isSameDay from 'date-fns/isSameDay'

export const sortEventsByDays = (events) => {
  let counter = 0
  if (!events.length) {
    return []
  }
  return events.reduce(
    (acc, cur, i, arr) => {
      if (i !== arr.length - 1) {
        if (isSameDay(arr[i].start, arr[i + 1].start)) {
          acc[counter] = [...acc[counter], arr[i + 1]]
          return acc
        }
        counter += 1
        acc = [...acc, [arr[i + 1]]]
        return acc
      }
      return acc
    },
    [[events[0]]]
  )
}

export const useSortEventsByDays = (events = [], startDate, endDate) => {
  return useMemo(() => {
    if (!startDate || !endDate) {
      return []
    }
    const daysAmount = differentInDays(endDate, startDate)
    const dates = [
      ...Array(daysAmount + 1)
        .fill(startDate)
        .map((o, i) => addDays(startDate, i))
        .filter((o) => !isWeekend(o))
    ]
    const e = events.filter(({ start }) => isAfter(start, startDate) && isBefore(start, endDate))
    return dates.map((day) => ({
      day,
      events: sortEventsByDays(e.filter((o) => isSameDay(o.start, day)))
    }))
  }, [events])
}
