import React, { useMemo, useContext, createContext } from 'react'
import axios from 'axios'

export const ApiContext = createContext({})

export const ApiProvider = ({ children }) => {
  const apiCloud = useMemo(() => {
    const config = {
      baseURL: 'https://us-central1-distributed-amp-196715.cloudfunctions.net/',
      headers: {
        'Content-Type': 'text/plain'
      }
    }

    const instance = axios.create(config)

    instance.interceptors.response.use((res) => {
      // token is expired
      const code = res.data?.error?.code
      if (code === 401 || code === 403) {
        sessionStorage.clear()
        window.location.reload()
      }

      return res
    })

    return instance
  }, [])

  const contextValue = useMemo(() => {
    return {
      apiCloud
    }
  }, [apiCloud])

  return <ApiContext.Provider value={contextValue}>{children}</ApiContext.Provider>
}

export const useApi = () => useContext(ApiContext)
