import React from 'react'
import { Box, Button, Link, Stack, Typography } from '@mui/material'

import { CanonicalHelmet } from 'components'
import { NudgesSection } from './NudgesSection'
import { AnalyticsSection } from './AnalyticsSection'
import { CallToAction } from './CallToAction'
import { FeaturesSection } from './FeaturesSection'
import { FaqsSection } from './FaqsSection'
import { BenefitsSection } from './BenefitsSection'

import card_background from 'assets/images/layered-peaks-haikei.svg'
import l_main from 'assets/images/l_main.svg'

export const WelcomePage = () => {
  return (
    <>
      <CanonicalHelmet />
      <Box
        sx={{
          width: '100%',
          position: 'relative',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundImage: `url(${card_background})`,
          paddingTop: 12
        }}>
        <Box padding="0 24px">
          <Stack
            spacing={3}
            sx={{ maxWidth: 800, margin: '0 auto', textAlign: 'center' }}
            alignItems="center">
            <Typography variant="h3" component="h1" fontWeight={600}>
              Sync and Manage Multiple Calendars
            </Typography>
            <Typography variant="h6">
              Dailyduck syncs Google calendars, simplifies multiple calendars management and
              improves work schedule.{' '}
              <strong style={{ color: '#3751ff' }}>
                Get a 3-month trial without a credit card.
              </strong>
            </Typography>
            <Button size="large" variant="contained" component={Link} href="/login">
              Try Now!
            </Button>
          </Stack>
        </Box>
        <Box mt={10} p="0 32px">
          <Box maxWidth={800} margin="0 auto">
            <img src={l_main} style={{ width: '100%' }} />
          </Box>
        </Box>
      </Box>
      <FeaturesSection />
      <NudgesSection />
      <AnalyticsSection />
      <BenefitsSection />
      <FaqsSection />
      <CallToAction />
    </>
  )
}
