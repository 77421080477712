import React from 'react'
import { Helmet } from 'react-helmet'

export const CanonicalHelmet = () => {
  return (
    <Helmet>
      <link rel="canonical" href={window.location.href} />
    </Helmet>
  )
}
