import React from 'react'
import { Outlet } from 'react-router-dom'
import { Box, Stack } from '@mui/material'

import { Header } from '../Header'
import { Footer } from '../Footer'

export const LandingLayout = () => {
  return (
    <Stack minHeight="100vh">
      <Header />
      <Box flex={1}>
        <Outlet />
      </Box>
      <Footer />
    </Stack>
  )
}
