import { useQuery } from 'react-query'

import { useApi } from 'api/Api'

export const useGetAccounts = (email) => {
  const { apiCloud } = useApi()

  return useQuery(['/show_account_calendars'], async () => {
    const results = await apiCloud.post('/show_account_calendars', { email })

    const { calendar_accounts, errors } = results.data
    const { expired_refresh, main_expired_refresh } = errors

    return { calendar_accounts, expired_accounts: expired_refresh }
  })
}

export const useGetBackgroundSyncs = (account_email) => {
  const { apiCloud } = useApi()

  return useQuery(['/get_background_syncs'], async () => {
    const results = await apiCloud.post('/get_background_syncs', { account_email })

    const { data: syncs } = results.data

    return syncs
  })
}
