import React from 'react'
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress'

export const MetricCardCircularProgress = ({ isActive, ...props }) => {
  return (
    <div style={{ position: 'relative', marginLeft: 'auto', width: 55 }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: '#9FA2B4'
        }}
        size={55}
        thickness={4.5}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        sx={{
          color: isActive ? '#3751FF' : '#18181B',
          position: 'absolute',
          left: 0,
          transition: 'none',
          [`& .${circularProgressClasses.circle}`]: {
            transition: 'stroke-dashoffset 1s cubic-bezier(.16,.58,.67,.98)'
          }
        }}
        size={55}
        thickness={4.5}
        {...props}
      />
    </div>
  )
}
