import React, { useContext, useMemo, useState } from 'react'
import isSameDay from 'date-fns/isSameDay'

import { useAuth } from '../auth/AuthContext'

const DateContext = React.createContext()

export const useDate = () => useContext(DateContext)

export const DateProvider = ({ children }) => {
  const { events } = useAuth()
  const [clickedDate, setClickedDate] = useState(null)
  const [activeWeek, setActiveWeek] = useState({})
  const [activeMetric, setActiveMetric] = useState('events_load')

  const today = new Date()

  const eventsInToday = useMemo(
    () => events.filter(({ start }) => isSameDay(today, start)),
    [events, today]
  )

  const contextValue = useMemo(
    () => ({
      eventsInToday,
      clickedDate,
      setClickedDate,
      activeWeek,
      setActiveWeek,
      activeMetric,
      setActiveMetric
    }),
    [
      eventsInToday,
      clickedDate,
      setClickedDate,
      activeWeek,
      setActiveWeek,
      activeMetric,
      setActiveMetric
    ]
  )

  return <DateContext.Provider value={contextValue}>{children}</DateContext.Provider>
}
