import React from 'react'
import { Box, Link, Stack, Typography } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'

import f_1 from 'assets/images/f_1.svg'
import f_2 from 'assets/images/f_2.svg'
import f_3 from 'assets/images/f_3.svg'
import f_4 from 'assets/images/f_4.svg'

const content = [
  {
    header: 'Add and Synchronise Multiple Calendars in Real-time',
    text: 'Effortlessly sync multiple Google Calendars all at once with Dailyduck. You pick the calendars you want to keep in sync, and Dailyduck handles the rest smoothly. Say goodbye to manual copying and meeting conflicts in your schedule!',
    image: f_1
  },
  {
    header: 'Privacy-First Calendar Integration',
    text: 'We safeguard your personal commitments, assist freelancers, and enhance schedule management. Customise your visibility for improved collaboration without compromises. Streamlined, secure, and personalised calendar experience just for you.',
    image: f_2
  },
  {
    header: 'Navigate Meetings with Ease',
    text: 'Utilise our workload heatmap to plan your week. Quickly jump into calls with just one click using our sidebar. Simplify your calendar experience and enhance your daily productivity.',
    image: f_3
  },
  {
    header: 'Smart Calendar View',
    text: 'Get personalised tips to improve your productivity and work-life balance. We analyse your schedule and provide suggestions, allowing you to focus on the most important tasks and safeguard your well-being. Say goodbye to burnout and frequent context-switching.',
    image: f_4
  }
]

export const FeaturesSection = () => {
  return (
    <Box sx={{ padding: '96px 0', backgroundColor: '#f9fafb' }} id="features">
      <Box padding="0 16px">
        <Typography variant="h4" component="h2" fontWeight={700} textAlign="center">
          Sync and Manage Google Calendar Easily
        </Typography>
      </Box>
      <Box maxWidth={1100} margin="80px auto 0">
        <Stack spacing={8} padding="0 16px">
          {content.map(({ header, text, image }, index) => (
            <Stack
              key={text}
              direction={{ xs: 'column', md: index % 2 ? 'row' : 'row-reverse' }}
              justifyContent="space-between"
              alignItems="center"
              spacing={5}>
              <Stack spacing={2} maxWidth={500}>
                <Typography variant="h5" fontWeight={700}>
                  {header}
                </Typography>
                <Typography fontSize={18} color="#6b7280">
                  {text}
                </Typography>
                {/*<Link*/}
                {/*  fontWeight={700}*/}
                {/*  size="large"*/}
                {/*  display="inline-flex"*/}
                {/*  alignItems="center"*/}
                {/*  gap={1}>*/}
                {/*  Learn more <ArrowForward fontSize="small" />*/}
                {/*</Link>*/}
              </Stack>
              <Box
                display="flex"
                alignItems="center"
                width="100%"
                justifyContent={{
                  xs: 'center',
                  md: index === content.length - 1 ? 'center' : index % 2 ? 'end' : 'start'
                }}
                flex={1}>
                <img src={image} style={{ minWidth: 300, maxWidth: 400 }} />
              </Box>
            </Stack>
          ))}
        </Stack>
      </Box>
    </Box>
  )
}
