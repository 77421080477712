import styled from 'styled-components'
import { NUDGES } from 'nudges/constants'

export const StyledCalendar = styled.div({}, ({ highlight, activeNudge }) =>
  highlight.map(
    (o) =>
      `.fc-timegrid-cols {
        td {
          &[data-date='${o}'] {
            .fc-timegrid-col-events {
              .fc-timegrid-event-harness {
                .overlay {
                  .fc-event-main {
                    ${activeNudge === NUDGES.OVERLAYS && 'background-color: #FBE4EA;'}
                    ${activeNudge === NUDGES.OVERLAYS && 'border-color: #E7BAC6;'}
                  }  
                }
                .noBreak {
                  .fc-event-main {
                    ${activeNudge === NUDGES.MEETINGS_IN_A_ROW && 'background-color: #FBE4EA;'}
                    ${activeNudge === NUDGES.MEETINGS_IN_A_ROW && 'border-color: #E7BAC6;'}
                  }  
                }                    
              }               
            }            
            .fc-timegrid-now-indicator-container {
              ${
                activeNudge === NUDGES.DAY_WITHOUT_LUNCH &&
                `
                .lunch-time {        
                  position: absolute; 
                  width: 100%;                
                  top: 672px;              
                  height: 168px;               
                  border-radius: 6px;              
                  border: 1px solid #E34C70;        
                  z-index: 3;           
                }
              `
              }
            }             
            ${
              (activeNudge === NUDGES.MEETINGS_OVERLOAD ||
                activeNudge === NUDGES.MEETINGS_AMOUNT ||
                activeNudge === NUDGES.NO_FOCUS_TIME) &&
              'background: #fbe4ea!important;'
            }          
          }
        }
      }`
  )
)
