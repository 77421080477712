import React from 'react'
import {
  Avatar,
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  Skeleton,
  Stack,
  Switch,
  useMediaQuery
} from '@mui/material'
import { DeleteOutline, Sync } from '@mui/icons-material'

export const SyncPairSkeleton = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down('md'))

  return (
    <ListItem sx={{ border: '1px solid lightgray', borderRadius: 2, cursor: 'pointer' }}>
      <Grid container spacing={{ xs: 2, sm: 0 }}>
        <Grid item xs={12} sm={6} md={8}>
          <Stack direction={{ md: 'row' }} alignItems={{ md: 'center' }} flexGrow={1}>
            <ListItem disablePadding sx={{ flexGrow: 1, maxWidth: matches ? '100%' : '45%' }}>
              <ListItemAvatar>
                <Skeleton variant="circular">
                  <Avatar alt="avatar" src={''} />
                </Skeleton>
              </ListItemAvatar>
              <Stack spacing={0.3} mt={1.5} mb={1.5}>
                <Skeleton variant="text" sx={{ height: '15px', width: '80px' }} />
                <Skeleton variant="text" sx={{ height: '15px', width: '120px' }} />
              </Stack>
            </ListItem>

            <Box display="flex" sx={{ margin: matches ? '0 8px' : '0 auto' }}>
              <Sync color="disabled" />
            </Box>

            <ListItem disablePadding sx={{ flexGrow: 1, maxWidth: matches ? '100%' : '45%' }}>
              <ListItemAvatar>
                <Skeleton variant="circular">
                  <Avatar alt="avatar" src={''} />
                </Skeleton>
              </ListItemAvatar>
              <Stack spacing={0.3} mt={1.5} mb={1.5}>
                <Skeleton variant="text" sx={{ height: '15px', width: '80px' }} />
                <Skeleton variant="text" sx={{ height: '15px', width: '120px' }} />
              </Stack>
            </ListItem>
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
          sm={3}
          md={3}
          display="flex"
          alignItems="center"
          justifyContent={{ xs: 'start', sm: 'center', md: 'end' }}>
          <FormControlLabel
            control={<Switch defaultChecked size="small" disabled />}
            label="Active"
          />
        </Grid>

        <Grid item xs={12} sm={3} md={1} display="flex" alignItems="center" justifyContent="end">
          <IconButton disabled>
            <DeleteOutline />
          </IconButton>
        </Grid>
      </Grid>
    </ListItem>
  )
}
