import React from 'react'
import { IconButton } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'

import { useDate } from 'Date/DateContext'
import { TooltipContent } from 'components/MetricColorPrompt/TooltipContent'
import { METRICS_TYPES } from 'metrics/constants'
import { TypographyText } from 'components/Typography'
import { MetricColorTooltip } from './styled'

export const MetricColorPrompt = () => {
  const { activeMetric } = useDate()
  const metricName = METRICS_TYPES.find(({ value }) => value === activeMetric).name

  return (
    <TypographyText size={14} weight={600} color="#5C5C62">
      {metricName}
      <MetricColorTooltip arrow placement="right" title={<TooltipContent metric={activeMetric} />}>
        <IconButton size="small" color="primary">
          <InfoOutlined fontSize="small" />
        </IconButton>
      </MetricColorTooltip>
    </TypographyText>
  )
}
