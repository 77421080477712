import React, { useState } from 'react'
import {
  Avatar,
  Box,
  FormControlLabel,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Switch,
  CircularProgress,
  useMediaQuery,
  Grid,
  Badge
} from '@mui/material'
import { ArrowForward, DeleteOutline, Error, Sync } from '@mui/icons-material'
import { createStyles, makeStyles } from '@mui/styles'
import { iconButtonClasses } from '@mui/material/IconButton'

import { ConfirmModal } from '../ConfirmModal/ConfirmModal'
import { useAuth } from 'auth/AuthContext'
import { useUnsubscribeFromBackgroundSyncer, useToggleBackgroundSyncStatus } from './hooks'
import logo from 'assets/images/duck.svg'

const useStyles = makeStyles((theme) =>
  createStyles({
    lightning: {
      animation: '$light 1s linear infinite'
    },
    '@keyframes light': {
      '0%': {
        color: 'black'
      },
      '50%': {
        color: theme.palette.primary.main
      },
      '100%': {
        color: 'black'
      }
    }
  })
)

export const SyncPair = ({ sourceAccount, targetAccount, direction, active, id }) => {
  const classes = useStyles()
  const matches = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const { user } = useAuth()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isChecked, setIsChecked] = useState(active)
  const { mutate: unsyncCalendars, isLoading: isUnsubscribing } =
    useUnsubscribeFromBackgroundSyncer()
  const { mutate: toggleBackgroundSyncStatus, isLoading } = useToggleBackgroundSyncStatus()

  const hasExpiredAccount = sourceAccount.expired || targetAccount.expired

  return (
    <ListItem
      sx={{
        border: '1px solid lightgray',
        borderRadius: 2,
        cursor: 'pointer',
        opacity: isUnsubscribing ? 0.5 : 1,
        pointerEvents: isUnsubscribing ? 'none' : 'initial',
        transition: (theme) =>
          theme.transitions.create(['box-shadow'], {
            duration: theme.transitions.duration.standard
          }),
        '&:hover': {
          boxShadow: 5,
          [`& .${iconButtonClasses.root}`]: {
            opacity: 1,
            transition: (theme) =>
              theme.transitions.create('all', {
                duration: theme.transitions.duration.standard
              })
          }
        }
      }}>
      <Grid container spacing={{ xs: 2, sm: 0 }}>
        <Grid item xs={12} sm={6} md={8}>
          <Stack direction={{ md: 'row' }} alignItems={{ md: 'center' }} flexGrow={1}>
            <ListItem
              component="div"
              disablePadding
              sx={{ flexGrow: 1, maxWidth: matches ? '100%' : '45%' }}>
              <ListItemAvatar>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    sourceAccount.expired ? <Error color="error" fontSize="small" /> : null
                  }>
                  <Avatar
                    alt={sourceAccount?.expired || 'Expired Account'}
                    src={sourceAccount?.picture || logo}
                  />
                </Badge>
              </ListItemAvatar>
              <ListItemText
                primary={sourceAccount?.name || 'Expired Account'}
                secondary={sourceAccount?.email || ''}
                primaryTypographyProps={{
                  color: sourceAccount.expired ? '#d32e2e' : '#191919'
                }}
              />
            </ListItem>

            <Box
              className={isChecked && classes.lightning}
              sx={{ margin: matches ? '5px 8px 0' : '5px auto 0' }}>
              {direction === 1 ? (
                <ArrowForward sx={{ rotate: matches ? '90deg' : '0' }} />
              ) : (
                <Sync />
              )}
            </Box>

            <ListItem
              component="div"
              disablePadding
              sx={{ flexGrow: 1, maxWidth: matches ? '100%' : '45%' }}>
              <ListItemAvatar>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    targetAccount.expired ? <Error color="error" fontSize="small" /> : null
                  }>
                  <Avatar
                    alt={targetAccount?.expired || 'Expired Account'}
                    src={targetAccount?.picture || logo}
                  />
                </Badge>
              </ListItemAvatar>
              <ListItemText
                primary={targetAccount?.name || 'Expired Account'}
                secondary={targetAccount?.email || ''}
                primaryTypographyProps={{
                  color: targetAccount.expired ? '#d32e2e' : '#191919'
                }}
              />
            </ListItem>
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
          sm={3}
          md={3}
          display="flex"
          alignItems="center"
          justifyContent={{ xs: 'start', sm: 'center', md: 'end' }}>
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <Switch
                disabled={isLoading || user.plan === 'FREE' || hasExpiredAccount}
                checked={isChecked}
                onChange={() => {
                  toggleBackgroundSyncStatus({
                    account_email: user.email,
                    id: id,
                    toggle: isChecked ? 0 : 1
                  })
                  setIsChecked(!isChecked)
                }}
                size="small"
              />
            }
            label={isChecked ? 'Active' : 'Disabled'}
          />
          {isLoading && (
            <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 2 }}>
              <CircularProgress size={20} />
            </Box>
          )}
        </Grid>

        <Grid item xs={12} sm={3} md={1} display="flex" alignItems="center" justifyContent="end">
          <IconButton onClick={() => setIsModalOpen(true)} sx={{ opacity: matches ? 1 : 0 }}>
            <DeleteOutline />
          </IconButton>
        </Grid>
      </Grid>
      <ConfirmModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        onConfirm={() => unsyncCalendars({ id, account_email: user.email })}
        title="Remove sync pair"
        text="Are you sure you want to remove this sync pair?"
      />
    </ListItem>
  )
}
