import React from 'react'
import { Box, Grid, Stack, Typography } from '@mui/material'
import {
  AllInclusive,
  FilterCenterFocus,
  JoinRight,
  Layers,
  Restaurant,
  TableRows
} from '@mui/icons-material'

const content = [
  {
    header: 'Focus Time Days:',
    text: 'Continuous work without dedicated focus time leads to burnout. Enhance concentration and accomplish important tasks by scheduling dedicated Focus Time placeholders in your calendar.',
    icon: FilterCenterFocus
  },
  {
    header: 'Over 7 Meetings per Day:',
    text: 'Having over 7 meetings in a day can lead to burnout. Improve calendar management and schedule breaks to maintain energy and engagement.',
    icon: Layers
  },
  {
    header: 'Multiple Events in a Row:',
    text: 'Back-to-back events in your calendar schedule can hinder focus and diminish overall effectiveness. Space out these events to allow for mental breaks, preventing Zoom fatigue.',
    icon: TableRows
  },
  {
    header: 'Working Day over 8 Hours:',
    text: 'Extending your working day beyond 8 hours may lead to burnout. Ensure to optimise tasks, manage priorities, and set boundaries within your calendar schedule to maintain a healthy work-life balance.',
    icon: AllInclusive
  },
  {
    header: 'Missed Lunches:',
    text: 'Skipping lunches negatively impacts well-being and hampers sustained energy levels. Prioritise breaks, including scheduling a proper lunchtime in your calendar, to recharge and maintain overall health.',
    icon: Restaurant
  },
  {
    header: 'Meeting Overlays:',
    text: 'Conflicting meetings can lead to context switching and a lack of focus. Manage your calendar effectively by avoiding overlapping meetings and ensuring a structured, efficient schedule.',
    icon: JoinRight
  }
]

export const NudgesSection = () => {
  return (
    <Box sx={{ width: '100%', backgroundColor: '#181d25', padding: '80px 0' }}>
      <Box sx={{ margin: '0 auto', maxWidth: '1200px', padding: '0 16px' }}>
        <Stack spacing={4} sx={{ margin: '0 auto', maxWidth: '600px', textAlign: 'center' }}>
          <Typography variant="h4" component="h2" color="#FFFFFF" fontWeight={700}>
            Optimise your schedule
          </Typography>
          <Typography variant="body2" color="#c6ced7" fontSize={18}>
            Dailyduck highlights your schedule issues and provides you with tips on how to boost
            productivity at work. Try it with our Smart Calendar View.
          </Typography>
        </Stack>
        <Grid container mt={1} spacing={8}>
          {content.map((list) => (
            <Grid key={list.header} item xs={12} sm={6} md={4}>
              <Stack direction="row" spacing={2} justifyContent="center">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    minWidth: 48,
                    width: 48,
                    height: 48,
                    borderRadius: 2,
                    backgroundColor: '#3751ff'
                  }}>
                  <list.icon sx={{ color: '#fff' }} />
                </Box>
                <Box sx={{ color: '#fff' }} maxWidth={300} pt={1}>
                  <Typography fontSize={20} fontWeight={700}>
                    {list.header}
                  </Typography>
                  <Typography fontSize={16} mt={2} color="#c6ced7">
                    {list.text}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  )
}
