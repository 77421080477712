import React from 'react'
import { Link } from 'react-router-dom'
import {
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material'
import { EventNote, MenuOpen } from '@mui/icons-material'

import logo from 'assets/images/new_logo.svg'
import { navigationLinks } from '../../constants'

export const NavigationList = ({ isOpenSubDrawer, setIsExpanded, setIsOpenSubDrawer }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down('md'))

  return (
    <>
      <Stack direction="row" alignItems="center" p={2}>
        <img alt="logo" src={logo} style={{ width: 32, height: 32 }} />
        <Typography color="#fff" variant="h6" ml={2.5} fontWeight={700}>
          DailyDuck
        </Typography>
        {matches && (
          <IconButton
            size="medium"
            sx={{ marginLeft: 'auto' }}
            onClick={() => setIsExpanded(false)}>
            <MenuOpen fontSize="small" />
          </IconButton>
        )}
      </Stack>
      <List>
        {navigationLinks.map((link) => (
          <ListItemButton
            key={link.link}
            component={Link}
            to={link.link}
            onClick={() => matches && setIsExpanded(false)}
            sx={{
              padding: '8px 22px'
            }}>
            <ListItemIcon>
              <link.icon sx={{ width: 20, height: 20 }} />
            </ListItemIcon>
            <ListItemText primary={link.name} />
          </ListItemButton>
        ))}
      </List>
      <Divider />
      <List>
        <ListItemButton
          onClick={() => setIsOpenSubDrawer((prev) => !prev)}
          sx={{
            padding: '8px 22px'
          }}>
          <ListItemIcon>
            <EventNote
              sx={{
                width: 20,
                height: 20,
                color: isOpenSubDrawer ? 'secondary.main' : 'primary.main'
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Schedule"
            primaryTypographyProps={{
              color: isOpenSubDrawer ? 'secondary.main' : 'primary.main'
            }}
          />
        </ListItemButton>
      </List>
    </>
  )
}
