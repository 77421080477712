import { useQuery } from 'react-query'
import { useApi } from '../api/Api'
import { useAuth } from '../auth/AuthContext'

export const useGetEvents = (dateRange) => {
  const { apiCloud } = useApi()
  const { filterWeekends, user } = useAuth()

  const { data = [], isLoading } = useQuery(
    ['/get-events', dateRange],
    async () => {
      const token = sessionStorage.getItem('accessToken')
      const data = { token, email: user.email, ...dateRange }
      const response = await apiCloud.post('/get-events', data)
      const eventsFromApi = response.data.items
      const events = eventsFromApi.map(({ end, start, ...props }) => ({
        ...props,
        start: new Date(start.dateTime),
        end: new Date(end.dateTime),
        day: new Date(start.dateTime)
      }))
      return filterWeekends(events)
    },
    { enabled: !!dateRange?.timeMin && !!dateRange?.timeMax }
  )

  return {
    events: data,
    isLoading: isLoading
  }
}
