import { useMutation } from 'react-query'
import { useApi } from 'api/Api'

export const useSaveFeedback = () => {
  const { apiCloud } = useApi()

  return useMutation({
    mutationFn: async ({ email, feedback }) => {
      try {
        apiCloud.post('/save_user_feedback', {
          email: email,
          feedback_text: feedback
        })
      } catch (e) {
        console.log(e)
      }
    }
  })
}
