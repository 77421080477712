import educator from 'assets/images/educator.svg'
import devproductivity from 'assets/images/dev_productivity.svg'

export const posts = [
  {
    title: '10 Productivity Habits Promoted by Industry Leaders',
    description:
      'Explore the game-changing productivity habits endorsed by industry leaders, unveiling a roadmap for enhanced efficiency and success.',
    link: 'time-mastery',
    read: '2 minutes',
    image: educator
  },
  {
    title: 'Mastering Productivity Through Themed Days',
    description:
      'Unlock peak productivity with Themed Weeks: a strategic approach for developers, project managers, and UX/UI designers to streamline tasks.',
    link: 'max-productivity',
    read: '2 minutes',
    image: devproductivity
  }
]
