import React from 'react'
import styled from 'styled-components'

import { PERIODS } from 'metrics/constants'

const StyledDiv = styled.div`
  display: flex;
  line-height: 28px;
  cursor: pointer;
  div {
    width: 68px;
    text-align: center;
    font-size: 12px;
    background-color: #f1f3f6;
    color: #9fa2b4;
    font-weight: 700;
    &:first-child {
      border-radius: 10px 0 0 10px;
    }
    &:last-child {
      border-radius: 0 10px 10px 0;
    }
    &.active {
      background-color: #d7dcff;
      color: #3751ff;
    }
  }
`

export const PeriodToggler = ({ onChange, activePeriod }) => {
  return (
    <StyledDiv>
      <div
        className={activePeriod === PERIODS.QUARTER && 'active'}
        onClick={() => onChange(PERIODS.QUARTER)}>
        Weekly
      </div>
      <div
        className={activePeriod === PERIODS.YEAR && 'active'}
        onClick={() => onChange(PERIODS.YEAR)}>
        Monthly
      </div>
    </StyledDiv>
  )
}
