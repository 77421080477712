import { Drawer as MuiDrawer, styled as MuiStyled } from '@mui/material'

import { drawerClosedWidth, drawerWidth, subDrawerWidth } from '../../constants'

const openedMixin = (theme) => ({
  width: drawerWidth,
  overflowX: 'hidden',
  zIndex: theme.zIndex.drawer + 2,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.standard
  })
})

const closedMixin = (theme) => ({
  width: drawerClosedWidth,
  overflowX: 'hidden',
  zIndex: theme.zIndex.drawer + 2,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.standard
  })
})

export const Drawer = MuiStyled(MuiDrawer)(({ theme, open = true }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

const openedMixinSub = (theme, isExpandedDrawer) => ({
  width: subDrawerWidth,
  overflowX: 'hidden',
  padding: 16,
  marginLeft: isExpandedDrawer ? drawerWidth : drawerClosedWidth,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.standard
  })
})

const closedMixinSub = (theme, isExpandedDrawer) => ({
  width: subDrawerWidth,
  overflowX: 'hidden',
  padding: 16,
  marginLeft: isExpandedDrawer ? -60 : -240,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.standard
  })
})

export const SubDrawer = MuiStyled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'isExpandedDrawer'
})(({ theme, open = true, isExpandedDrawer }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixinSub(theme, isExpandedDrawer),
    '& .MuiDrawer-paper': openedMixinSub(theme, isExpandedDrawer)
  }),
  ...(!open && {
    ...closedMixinSub(theme, isExpandedDrawer),
    '& .MuiDrawer-paper': closedMixinSub(theme, isExpandedDrawer)
  })
}))

export const SimpleDrawer = MuiStyled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'isSubDrawer'
})(({ isSubDrawer = false }) => ({
  width: isSubDrawer ? subDrawerWidth : drawerWidth,
  padding: isSubDrawer ? 16 : 0,
  '& .MuiDrawer-paper': {
    width: isSubDrawer ? subDrawerWidth : drawerWidth,
    padding: isSubDrawer ? 16 : 0,
    backgroundColor: '#18181b'
  }
}))
