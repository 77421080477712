import React from 'react'
import {
  Avatar,
  Box,
  FormControl,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  InputAdornment,
  CircularProgress,
  Chip,
  Divider,
  Typography
} from '@mui/material'
import { AddCircle } from '@mui/icons-material'
import { useAccountLogin } from '../../hooks'
import { SelectAccountSkeleton } from './SelectAccountSkeleton'

export const SelectAccount = ({ items, account, setAccount, isLoading }) => {
  const { login, isLoading: isLoadingNewCalendar } = useAccountLogin()

  const handleChange = (event) => {
    if (event.target.value !== 'new') {
      setAccount(event.target.value)
    } else {
      setAccount(null)
    }
  }

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <Select
          value={account}
          onChange={handleChange}
          displayEmpty
          placeholder="Choose calendar"
          size="small"
          disabled={isLoading}
          endAdornment={
            (isLoading || isLoadingNewCalendar) && (
              <InputAdornment position="end" sx={{ paddingRight: 2 }}>
                <CircularProgress size={20} />
              </InputAdornment>
            )
          }>
          {items.map((account) => (
            <MenuItem key={account.email} value={account.email}>
              <ListItem disablePadding>
                <ListItemAvatar>
                  <Avatar alt="avatar" src={account.picture} />
                </ListItemAvatar>
                <ListItemText primary={account.name} secondary={account.email} />
                {account.main && (
                  <Chip label="main" variant="outlined" size="small" sx={{ marginLeft: 1 }} />
                )}
              </ListItem>
            </MenuItem>
          ))}
          {isLoadingNewCalendar && <SelectAccountSkeleton />}
          {!isLoadingNewCalendar && (
            <>
              <Divider />
              <MenuItem value="new" onClick={login}>
                <Typography display="inline-flex">
                  Add new calendar{' '}
                  <Box ml={1}>
                    <AddCircle />
                  </Box>
                </Typography>
              </MenuItem>
            </>
          )}
        </Select>
      </FormControl>
    </Box>
  )
}
