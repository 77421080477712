export const PERIODS = {
  QUARTER: 'quarter',
  YEAR: 'year'
}

export const PERIODS_OPTIONS = [
  {
    value: PERIODS.QUARTER,
    label: 'Quarter',
    aggregationPeriod: 5,
    formatDate: 'dd.MM'
  },
  {
    value: PERIODS.YEAR,
    label: 'Year',
    aggregationPeriod: 20,
    formatDate: 'MMM’ yy'
  }
]

export const METRICS = {
  EVENTS_LOAD: 'events_load',
  FOCUS_TIME: 'focus_time',
  EVENTS_WITHOUT_PAUSE: 'events_without_pause',
  MISSED_LUNCHES: 'missed_lunches'
}

export const METRICS_TYPES = [
  {
    value: METRICS.EVENTS_LOAD,
    name: 'Time spent in meetings',
    unit: 'h',
    full_unit: 'hours',
    tooltip: 'Average time per last 4 weeks you spent in meetings each day',
    benchmark: {
      quarter: '4',
      year: '4'
    }
  },
  {
    value: METRICS.EVENTS_WITHOUT_PAUSE,
    name: 'Events in a row',
    unit: '',
    full_unit: 'events',
    tooltip:
      'Average amount of meetings per last 4 weeks without pause each day — at least 5 minutes break between each meeting.',
    benchmark: {
      quarter: '2',
      year: '2'
    }
  },
  {
    value: METRICS.FOCUS_TIME,
    name: 'No Focus time days',
    unit: '',
    full_unit: 'days',
    tooltip:
      'Amount of days during last 4 weeks you had no time for focus — at least 3 hour per day for concentrated work',
    benchmark: {
      quarter: '0',
      year: '0'
    }
  },
  {
    value: METRICS.MISSED_LUNCHES,
    name: 'Missed lunches',
    unit: '',
    full_unit: 'lunches',
    tooltip: 'Amount of lunches you missed per last 4 weeks',
    benchmark: {
      quarter: '0',
      year: '2'
    }
  }
]

export const BENCHMARKS = {
  events_load: {
    quarter: '4',
    year: '4'
  },
  events_without_pause: {
    quarter: '2',
    year: '2'
  },
  focus_time: {
    quarter: '1',
    year: '4'
  },
  missed_lunches: {
    quarter: '0',
    year: '2'
  }
}

export const DAILY_LOAD = [
  {
    value: null,
    backgroundColor: 'transparent',
    color: ''
  },
  {
    value: 0,
    backgroundColor: '#203626',
    color: '#4CE364'
  },
  {
    value: 1,
    backgroundColor: '#203626',
    color: '#4CE364'
  },
  {
    value: 2,
    backgroundColor: '#203626',
    color: '#4CE364'
  },
  {
    value: 3,
    backgroundColor: '#363622',
    color: '#E3DD4C'
  },
  {
    value: 4,
    backgroundColor: '#363622',
    color: '#E3DD4C'
  },
  {
    value: 5,
    backgroundColor: '#363622',
    color: '#E3DD4C'
  },
  {
    value: 6,
    backgroundColor: '#362028',
    color: '#E34C70'
  },
  {
    value: 7,
    backgroundColor: '#362028',
    color: '#E34C70'
  },
  {
    value: 8,
    backgroundColor: '#362028',
    color: '#E34C70'
  }
]

export const DAILY_MISSED_LUNCHES = [
  {
    value: null,
    backgroundColor: 'transparent',
    color: ''
  },
  {
    value: 1,
    backgroundColor: '#203626',
    color: '#4CE364'
  },
  {
    value: 0,
    backgroundColor: '#362028',
    color: '#E34C70'
  }
]

export const DAILY_EVENTS_WITHOUT_PAUSE = [
  {
    value: null,
    backgroundColor: 'transparent',
    color: ''
  },
  {
    value: 0,
    backgroundColor: '#203626',
    color: '#4CE364'
  },
  {
    value: 2,
    backgroundColor: '#203626',
    color: '#4CE364'
  },
  {
    value: 3,
    backgroundColor: '#363622',
    color: '#E3DD4C'
  },
  {
    value: 4,
    backgroundColor: '#363622',
    color: '#E3DD4C'
  },
  {
    value: 5,
    backgroundColor: '#362028',
    color: '#E34C70'
  },
  {
    value: 6,
    backgroundColor: '#362028',
    color: '#E34C70'
  }
]

export const DAILY_DAYS_WITHOUT_FOCUS_TIME = [
  {
    value: null,
    backgroundColor: 'transparent',
    color: ''
  },
  {
    value: 0,
    backgroundColor: '#203626',
    color: '#4CE364'
  },
  {
    value: 1,
    backgroundColor: '#362028',
    color: '#E34C70'
  }
]
