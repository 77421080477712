import { useState } from 'react'
import { useGoogleLogin } from '@react-oauth/google'
import axios from 'axios'

import { useAuth } from 'auth/AuthContext'
import { useApi } from 'api/Api'
import { SCOPES } from 'config/config'
import { useAddAccount } from './useAddAccount'

export const useAccountLogin = () => {
  const { user } = useAuth()
  const { mutate: addAccount, isLoading: isLoadingNewAccount } = useAddAccount()
  const { apiCloud } = useApi()
  const [isLoading, setIsLoading] = useState(false)

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setIsLoading(true)
      const { data } = await apiCloud.post('/exchange_code2', {
        code: tokenResponse.code
      })

      const { data: profile } = await axios.get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${data.tokens.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: 'application/json'
          }
        }
      )
      setIsLoading(false)

      await addAccount({
        source_email: user.email,
        calendar_email: profile.email,
        refresh_token: data.tokens.refresh_token
      })
    },
    scope: SCOPES,
    select_account: true,
    flow: 'auth-code'
  })

  return { login, isLoading: isLoading || isLoadingNewAccount }
}
