import React from 'react'
import { Box, Link, Stack, Typography } from '@mui/material'

import logo from 'assets/images/new_logo.svg'

export const Footer = () => {
  return (
    <Box p="40px 20px" sx={{ backgroundColor: '#181d25', color: '#ffffff' }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={{ xs: 'column', sm: 'row' }}
        maxWidth={1240}
        m="0 auto"
        gap={1}>
        <Stack spacing={1} alignItems={{ xs: 'center', sm: 'start' }}>
          <Box display="inline-flex" alignItems="center" gap={1}>
            <img src={logo} style={{ maxWidth: 30 }} />
            <Typography fontSize={24} fontWeight={700}>
              dailyduck
            </Typography>
          </Box>
          <Stack direction="row" spacing={1.5}>
            <Link underline="hover" href="/terms-of-service" fontSize={14} color="#ffffff">
              Terms of Service
            </Link>
            <Link underline="hover" href="/privacy-policy" fontSize={14} color="#ffffff">
              Privacy Policy
            </Link>
          </Stack>
        </Stack>
        <Typography color="#FFFFFF99">© 2023 dailyduck.io</Typography>
      </Box>
    </Box>
  )
}
