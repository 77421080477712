import { BarChart, CalendarToday, Chat, Payment, Sync } from '@mui/icons-material'

export const drawerWidth = 240
export const drawerClosedWidth = 64
export const subDrawerWidth = 300

export const navigationLinks = [
  { name: 'Calendar sync', icon: Sync, link: '/calendar-sync' },
  { name: 'Analytics', icon: BarChart, link: '/analytics' },
  { name: 'Nudges', icon: CalendarToday, link: '/nudges' }
]

export const routesLinks = [
  ...navigationLinks,
  { name: 'Billing & Plans', icon: Payment, link: '/billing' },
  { name: 'Feedback', icon: Chat, link: '/feedback' }
]

export const SUBSCRIPTION_PLANS = {
  FREE: 'FREE',
  BASIC: 'BASIC'
}

export const PLANS_PRICE = {
  [SUBSCRIPTION_PLANS.FREE]: 0,
  [SUBSCRIPTION_PLANS.BASIC]: 5
}

export const PLANS_FEATURES = {
  [SUBSCRIPTION_PLANS.FREE]: [
    {
      name: 'Smart Calendar',
      checked: true
    },
    {
      name: 'Productivity Tips',
      checked: true
    },
    {
      name: 'Basic Statistics',
      checked: true
    },
    {
      name: 'Basic Support',
      checked: true
    },
    {
      name: 'Unlimited Calendars Syncs',
      checked: false
    }
  ],
  [SUBSCRIPTION_PLANS.BASIC]: [
    {
      name: 'Smart Calendar',
      checked: true
    },
    {
      name: 'Productivity Tips',
      checked: true
    },
    {
      name: 'Basic Statistics',
      checked: true
    },
    {
      name: 'Basic Support',
      checked: true
    },
    {
      name: 'Unlimited Calendars Syncs',
      checked: true
    }
  ]
}
