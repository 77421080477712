import React from 'react'
import { Outlet } from 'react-router-dom'
import { Box, ThemeProvider } from '@mui/material'

import { PageDrawer } from '../PageDrawer'
import { PageHeader } from '../PageHeader'
import { PageContent } from '../PageContent'
import { useDrawers } from 'drawersContext/drawersContext'

export const PageLayout = () => {
  const { isExpandedDrawer, setIsExpandedDrawer, responsiveTheme } = useDrawers()

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        flex: 1,
        position: 'relative',
        flexDirection: 'row',
        justifyContent: 'stretch',
        maxHeight: '100%',
        minHeight: '100%'
      }}>
      <PageDrawer isExpanded={isExpandedDrawer} setIsExpanded={setIsExpandedDrawer} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          overflow: 'hidden'
        }}>
        <PageHeader setIsExpandedDrawer={setIsExpandedDrawer} />
        <ThemeProvider theme={responsiveTheme}>
          <PageContent>
            <Outlet />
          </PageContent>
        </ThemeProvider>
      </Box>
    </Box>
  )
}
