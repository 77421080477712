import styled from 'styled-components'

export const CustomNudgeCard = styled.div`
  width: 208px;
  min-width: 208px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #dfe0eb;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  img {
    margin-bottom: 1px;
  }

  ${(props) =>
    props.isActive &&
    `
     color: #3751ff;
     border-color: #3751ff;
  `}

  ${(props) => props.isLoading && `opacity: .7`}
`
