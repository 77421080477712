import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  styled,
  Typography
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

const content = [
  {
    question: 'What calendar providers can I use?',
    answer:
      'Currently, we only support Google Calendar. In the near future, we will also add support for Microsoft Outlook.'
  },
  {
    question: 'Can I add calendars from other organizations?',
    answer:
      'Yes, you can use accounts from different organisations and even your private Google Calendar accounts.'
  },
  {
    question: 'How does this vary from importing an external calendar into my Google Calendar?',
    answer:
      "Events from imported calendars wont be visible to other members in your organization when scheduling meetings. They'll only see events in your primary calendar. That's why DailyDuck syncs your calendars, duplicating the events to your primary calendar instead of creating a new one."
  },
  {
    question: 'Will DailyDuck disrupt my calendar?',
    answer:
      "Not at all. DailyDuck simply clones events based on the sync configuration you've set up. We don't alter any existing events."
  }
]

const CustomAccordion = styled(Accordion)({
  boxShadow: 'none',
  borderRadius: 0,
  backgroundColor: '#f9fafb',
  '.MuiAccordionSummary-content': {
    margin: '20px 0',
    '.MuiTypography-root': {
      fontWeight: 700
    }
  },
  '.MuiAccordionDetails-root': {
    color: '#6b7280'
  }
})

export const FaqsSection = () => {
  return (
    <Box padding={{ xs: '96px 8px 0', md: '96px 96px 0' }} sx={{ backgroundColor: '#f9fafb' }}>
      <Box maxWidth={900} m="0 auto" padding="0 16px">
        <Box mb={4}>
          <Typography variant="h4" component="h2" fontWeight={600}>
            Frequently asked questions
          </Typography>
        </Box>
        {content.map(({ question, answer }) => (
          <CustomAccordion key={answer}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>{question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{answer}</Typography>
            </AccordionDetails>
          </CustomAccordion>
        ))}
      </Box>
    </Box>
  )
}
