import React from 'react'

import { MainCalendar } from '../../components/Calendar/MainCalendar'
import { Nudges, CanonicalHelmet } from 'components'

export const CalendarPage = () => {
  return (
    <>
      <CanonicalHelmet />
      <MainCalendar>{(props) => <Nudges {...props} />}</MainCalendar>
    </>
  )
}
