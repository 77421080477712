import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { AddCircle } from '@mui/icons-material'
import { Box, Button, IconButton, Stack, Tooltip } from '@mui/material'

import { SyncPair, SyncPairSkeleton, TypographyText } from 'components'
import { useAuth } from 'auth/AuthContext'
import { useGetBackgroundSyncs } from './hooks'
import no_syncs from 'assets/images/no_syncs.svg'
import { SUBSCRIPTION_PLANS } from '../../constants'

export const Synchranizations = ({ accounts, isLoadingAccounts }) => {
  const navigate = useNavigate()
  const { user } = useAuth()
  const { data: syncs = [], isLoading } = useGetBackgroundSyncs(user.email)

  const syncPairs = useMemo(
    () =>
      syncs.map((sync) => ({
        ...sync,
        sourceAccount: accounts.calendar_accounts.find(
          ({ email }) => email === sync.source_email
        ) ?? { email: sync.source_email, expired: true },
        targetAccount: accounts.calendar_accounts.find(
          ({ email }) => email === sync.target_email
        ) ?? { email: sync.target_email, expired: true }
      })),
    [syncs, accounts]
  )

  const handleClick = () => {
    navigate('/calendar-sync/create')
  }

  return (
    <Stack mt={4} mb={4} spacing={2} alignItems="center">
      {isLoading || isLoadingAccounts ? (
        <>
          <SyncPairSkeleton />
          <SyncPairSkeleton />
        </>
      ) : (
        <>
          {syncPairs.length ? (
            <>
              {syncPairs.map(({ id, direction, sourceAccount, targetAccount, active }) => (
                <SyncPair
                  key={id}
                  sourceAccount={sourceAccount}
                  targetAccount={targetAccount}
                  direction={direction}
                  active={active}
                  id={id}
                />
              ))}
              {user.plan === SUBSCRIPTION_PLANS.BASIC && (
                <Tooltip title="Create new sync" arrow>
                  <IconButton onClick={handleClick}>
                    <AddCircle />
                  </IconButton>
                </Tooltip>
              )}
            </>
          ) : (
            <Box>
              <Stack alignItems="center" spacing={2}>
                <img alt="No syncs yet" src={no_syncs} style={{ width: 250, height: 250 }} />
                <TypographyText size={16}>You have no calendar syncs yet</TypographyText>
                <Button variant="contained" onClick={handleClick} disabled={accounts.length === 0}>
                  Create first sync
                </Button>
              </Stack>
            </Box>
          )}
        </>
      )}
    </Stack>
  )
}
