import React, { useContext, useEffect, useMemo, useState } from 'react'
import isWeekend from 'date-fns/isWeekend'

const AuthContext = React.createContext()

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [events, setEvents] = useState([])
  const [user, setUser] = useState(null)
  const [initializing, setInitializing] = useState(true)

  useEffect(() => {
    const userInfo = sessionStorage.getItem('primaryUser')
    if (userInfo) {
      setUser(JSON.parse(userInfo))
    } else {
      setUser(null)
    }
    setInitializing(false)
  }, [])

  const isAuthenticated = useMemo(() => {
    return !!user
  }, [user])

  const filterWeekends = (events) =>
    events.filter(
      ({ start, end, day }) =>
        !isWeekend(start) &&
        !isNaN(start?.getTime()) &&
        !isNaN(end?.getTime()) &&
        !isNaN(day?.getTime())
    )

  const contextValue = useMemo(
    () => ({
      user,
      setUser,
      events,
      setEvents,
      isAuthenticated,
      initializing,
      setInitializing,
      filterWeekends
    }),
    [
      user,
      setUser,
      events,
      setEvents,
      isAuthenticated,
      initializing,
      setInitializing,
      filterWeekends
    ]
  )

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}
