import React from 'react'
import styled from 'styled-components'

import loader from './loader.gif'

const CustomLoader = styled.img`
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const PageLoader = () => {
  return <CustomLoader alt="loader" src={loader} />
}
