import {
  countDailyEventsWithoutPause,
  countDailyFocusTime,
  countDailyTimeEventsLoad,
  countMissedLunches
} from '../metrics/metrics'

export const useCountMetrics = (eventsByPeriods, period) => {
  return eventsByPeriods.map(({ day, events }) => {
    const res = events.reduce(
      (acc, cur) => {
        const eventsLoad = countDailyTimeEventsLoad(cur)
        const focusTime = countDailyFocusTime(cur)
        const eventsWithoutPause = countDailyEventsWithoutPause(cur)
        const missedLunches = countMissedLunches(cur)
        return {
          events_load: acc.events_load + eventsLoad,
          focus_time: acc.focus_time + focusTime,
          events_without_pause: acc.events_without_pause + eventsWithoutPause,
          missed_lunches: acc.missed_lunches - missedLunches
        }
      },
      {
        events_load: 0,
        focus_time: 0,
        events_without_pause: 0,
        missed_lunches: period
      }
    )
    return {
      day,
      events_load: (res.events_load / period).toFixed(1),
      focus_time: res.focus_time,
      events_without_pause: Number((res.events_without_pause / period).toFixed(0)),
      missed_lunches: res.missed_lunches
    }
  })
}
