import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { useApi } from 'api/Api'

export const useSubscribeToBackgroundSyncer = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { apiCloud } = useApi()

  return useMutation({
    mutationFn: async ({ account_email, source_email, target_email, active, direction }) => {
      const results = await apiCloud.post('/subscribe_to_background_syncer', {
        account_email,
        source_email,
        target_email,
        active,
        direction
      })

      return results.data
    },
    onSuccess: ({ syncs }) => {
      queryClient.setQueryData(['/get_background_syncs'], () => {
        return syncs
      })

      navigate('/calendar-sync')
    }
  })
}
