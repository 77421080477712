import React from 'react'
import {
  Box,
  Button,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography
} from '@mui/material'
import { Check, Close } from '@mui/icons-material'

export const PricingPage = () => {
  return (
    <Box maxWidth={1240} m="0 auto" p="40px 20px">
      <Typography variant="h3" component="h1" fontWeight={600} textAlign="center">
        Plans & Pricing
      </Typography>
      <Typography variant="h6" textAlign="center" mt={2}>
        Options may vary, but a plan is your compass to find a work-life balance
      </Typography>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ md: 'center' }}
        maxWidth={{ xs: 350, md: 1000 }}
        spacing={2}
        m="30px auto">
        <Paper variant="outlined" square={false} sx={{ flex: 1, p: 2, minHeight: 425 }}>
          <Box mb={2} textAlign="center">
            <Typography variant="h6">Free</Typography>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography variant="h5" fontWeight={700}>
                $0
              </Typography>
              <Typography sx={{ ml: 0.5 }}>/ month</Typography>
            </Box>
            <Button variant="outlined" sx={{ width: 150, mt: 2 }}>
              Try for free
            </Button>
          </Box>
          <List>
            <ListItem disablePadding sx={{ borderBottom: '1px solid lightgray', pt: 1, pb: 1 }}>
              <ListItemIcon>
                <Check color="success" />
              </ListItemIcon>
              <ListItemText primary="Smart Calendar" />
            </ListItem>
            <ListItem disablePadding sx={{ borderBottom: '1px solid lightgray', pt: 1, pb: 1 }}>
              <ListItemIcon>
                <Check color="success" />
              </ListItemIcon>
              <ListItemText primary="Productivity Tips" />
            </ListItem>
            <ListItem disablePadding sx={{ borderBottom: '1px solid lightgray', pt: 1, pb: 1 }}>
              <ListItemIcon>
                <Check color="success" />
              </ListItemIcon>
              <ListItemText primary="Basic Statistics" />
            </ListItem>
            <ListItem disablePadding sx={{ borderBottom: '1px solid lightgray', pt: 1, pb: 1 }}>
              <ListItemIcon>
                <Check color="success" />
              </ListItemIcon>
              <ListItemText primary="Basic Support" />
            </ListItem>
            <ListItem disablePadding sx={{ pt: 1, pb: 1 }}>
              <ListItemIcon>
                <Close />
              </ListItemIcon>
              <ListItemText primary="Unlimited Calendars Syncs" />
            </ListItem>
          </List>
        </Paper>
        <Paper
          variant="outlined"
          square={false}
          sx={{
            flex: 1,
            p: '28px 16px',
            zIndex: 100,
            minHeight: 425,
            borderColor: 'primary.main',
            boxShadow: 4
          }}>
          <Box mb={2} textAlign="center" position="relative">
            <Box position="absolute" right={-8} top={-20}>
              <Chip label="3 months free" size="small" />
            </Box>
            <Typography variant="h6">Basic</Typography>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography variant="h5" fontWeight={700}>
                $5
              </Typography>
              <Typography sx={{ ml: 0.5 }}>/ month</Typography>
            </Box>
            <Button variant="outlined" sx={{ width: 150, mt: 2 }}>
              Try for free
            </Button>
          </Box>
          <List>
            <ListItem disablePadding sx={{ borderBottom: '1px solid lightgray', pt: 1, pb: 1 }}>
              <ListItemIcon>
                <Check color="success" />
              </ListItemIcon>
              <ListItemText primary="Smart Calendar" />
            </ListItem>
            <ListItem disablePadding sx={{ borderBottom: '1px solid lightgray', pt: 1, pb: 1 }}>
              <ListItemIcon>
                <Check color="success" />
              </ListItemIcon>
              <ListItemText primary="Productivity Tips" />
            </ListItem>
            <ListItem disablePadding sx={{ borderBottom: '1px solid lightgray', pt: 1, pb: 1 }}>
              <ListItemIcon>
                <Check color="success" />
              </ListItemIcon>
              <ListItemText primary="Basic Statistics" />
            </ListItem>
            <ListItem disablePadding sx={{ borderBottom: '1px solid lightgray', pt: 1, pb: 1 }}>
              <ListItemIcon>
                <Check color="success" />
              </ListItemIcon>
              <ListItemText primary="Basic Support" />
            </ListItem>
            <ListItem disablePadding sx={{ pt: 1, pb: 1 }}>
              <ListItemIcon>
                <Check color="success" />
              </ListItemIcon>
              <ListItemText primary="Unlimited Calendars Syncs" />
            </ListItem>
          </List>
        </Paper>
        <Paper variant="outlined" square={false} sx={{ flex: 1, p: 2, minHeight: 425 }}>
          <Box mb={2} textAlign="center">
            <Typography variant="h6">Premium</Typography>
            <Typography variant="h5" fontWeight={700}>
              Coming Soon
            </Typography>
            <Button variant="outlined" sx={{ width: 150, mt: 2 }}>
              Contact us
            </Button>
          </Box>
        </Paper>
      </Stack>
    </Box>
  )
}
