import React, { useMemo, useContext, createContext, useState, useEffect } from 'react'
import { createTheme, useMediaQuery } from '@mui/material'
import { createBreakpoints } from '@mui/system'

import { drawerClosedWidth, drawerWidth, subDrawerWidth } from '../constants'
import { theme } from '../ThemeProvider/ThemeProvider'

export const DrawersContext = createContext({})

export const DrawersProvider = ({ children }) => {
  const drawerExist = useMediaQuery((theme) => theme.breakpoints.up('md'), { noSsr: true })

  const [isExpandedDrawer, setIsExpandedDrawer] = useState(true)
  const [isOpenSubDrawer, setIsOpenSubDrawer] = useState(true)

  useEffect(() => {
    // hide initially drawer on small screens
    if (!drawerExist) {
      setIsExpandedDrawer(false)
      setIsOpenSubDrawer(false)
    }
  }, [drawerExist])

  const drawerSize = drawerExist
    ? drawerClosedWidth +
      (isExpandedDrawer ? drawerWidth - drawerClosedWidth : 0) +
      (isOpenSubDrawer ? subDrawerWidth : 0)
    : 0

  const responsiveTheme = createTheme(theme, {
    breakpoints: createBreakpoints({
      values: {
        xs: 0,
        sm: 600 + drawerSize,
        md: 900 + drawerSize,
        lg: 1200 + drawerSize,
        xl: 1536 + drawerSize
      }
    })
  })

  const contextValue = useMemo(() => {
    return {
      isExpandedDrawer,
      isOpenSubDrawer,
      setIsExpandedDrawer,
      setIsOpenSubDrawer,
      responsiveTheme
    }
  }, [isExpandedDrawer, isOpenSubDrawer, setIsExpandedDrawer, setIsOpenSubDrawer, responsiveTheme])

  return <DrawersContext.Provider value={contextValue}>{children}</DrawersContext.Provider>
}

export const useDrawers = () => useContext(DrawersContext)
